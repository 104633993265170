import { ReactComponent as GoogleLogo } from 'assets/icons/google.svg';
import birdAnimation from 'assets/images/bird.json';
import cloudBig from 'assets/images/cloud_big_01.png';
import cloudSmall from 'assets/images/cloud_small_01.png';
import ground from 'assets/images/ground.png';
import yimLogo from 'assets/images/logo/logo_2021.png';
import tackle from 'assets/images/logo/logo_tackle.png';
import mountainBack from 'assets/images/mountain_back.png';
import mountainLeft from 'assets/images/mountain_front_left.png';
import mountainRight from 'assets/images/mountain_front_right.png';
import station from 'assets/images/rocket_base_back.png';
import barrier from 'assets/images/rocket_base_front.png';
import ProductHuntButton from 'components/ProductHuntButton';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { isMobile } from 'utils/misc';
import NewAppModal from './components/NewAppModal';
import PrivacyModal from './components/PrivacyModal';
import SpaceStuffs from './components/SpaceStuffs';

const WIDTH = window.innerWidth;
const isLargeScreen = WIDTH > 900;
const HEIGHT = window.innerHeight;
const isSmallScreen = HEIGHT < 768;

const Auth = ({ phase, loginHandler }) => {
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [newAppModalVisible, setNewAppModalVisible] = useState(false);
  const [bulbColorIdx, setBulbColorIdx] = useState(0);
  const combinedControls = useAnimation();
  const cloudControl = useAnimation();

  useEffect(() => {
    if (phase !== 'auth') {
      combinedControls.start('move');
      cloudControl.start('move');
    }
  }, [phase]);

  useEffect(() => {
    cloudControl.start('float');
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: birdAnimation,
    rendererSettings: {
      // preserveAspectRatio: "xMidYMid slice"
    },
  };

  return (
    <motion.div
      key="auth"
      variants={containerAnim}
      initial="auth"
      exit="exit"
      animate={phase}
      className={`absolute w-screen h-screen overflow-hidden ${phase !== 'auth' ? 'pointer-events-none' : ''}`}>
      {/* Gradient */}
      <motion.div
        className="absolute w-screen h-screen gradient-full pointer-events-none"
        style={{ height: '600vh' }}
        initial="initial"
        variants={gradientAnim}
        animate={combinedControls}
        transition={{ duration: 4 }}
      />

      {/* Mountain Silhouette */}
      <motion.div
        initial="initial"
        variants={bgMountainAnim}
        animate={combinedControls}
        className="absolute flex justify-center items-end w-screen pointer-events-none"
        style={{
          bottom: isLargeScreen ? 117 : 0,
          height: isLargeScreen ? 237 : 140,
          backgroundImage: `url(${mountainBack})`,
          backgroundSize: 'cover',
          pointerEvents: 'none',
        }}>
        {/* <img src={mountainBack} className="" style={{ maxWidth: '100%', height: '100%' }} /> */}
      </motion.div>

      {/* Ground */}
      <motion.div
        initial="initial"
        variants={groundAnim}
        animate={combinedControls}
        className="absolute flex justify-center items-end w-screen bottom-0 pointer-events-none"
        style={{ height: isLargeScreen ? 203 : 60 }}>
        <img src={ground} style={{ width: '100%', maxWidth: '100%', height: '100%' }} />
      </motion.div>

      {/* CloudsBig */}
      <motion.div
        initial="initial"
        variants={cloudAnim}
        animate={cloudControl}
        className="absolute sm:h-32 pointer-events-none"
        style={{ height: 114, bottom: isSmallScreen ? 350 : 425 }}>
        <img src={cloudBig} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* CloudsSmall */}
      <motion.div
        initial="initial"
        variants={cloudAnimSmall}
        animate={cloudControl}
        className="absolute sm:h-32 pointer-events-none"
        style={{ height: 86, top: 60, right: 40 }}>
        <img src={cloudSmall} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* Launch Pad */}
      <motion.div
        initial="initial"
        variants={groundAnim}
        animate={combinedControls}
        className="absolute w-screen pointer-events-none origin-bottom"
        style={{
          bottom: isSmallScreen || isMobile ? 5 : 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: isLargeScreen ? 416 : 300,
          scale: isSmallScreen || isMobile ? 0.6 : 1,
        }}>
        <div className="relative">
          <img src={station} style={{ top: 0, width: isLargeScreen ? 416 : 300 }} />
          <div
            className="border animate-ping"
            style={{
              width: 20,
              height: 20,
              borderRadius: 20,
              position: 'absolute',
              top: isLargeScreen ? 95 : 70,
              left: 58,
            }}
          />
          <div
            className="glowBox"
            style={{
              width: 20,
              height: 20,
              borderRadius: 20,
              position: 'absolute',
              top: isSmallScreen || isMobile ? 95 : 95,
              left: 58,
            }}
          />
        </div>
      </motion.div>

      {/* Barrier */}
      <motion.div
        initial="initial"
        variants={groundAnim}
        animate={combinedControls}
        className="absolute w-screen pointer-events-none origin-bottom"
        style={{
          bottom: isSmallScreen ? 15 : 60,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          height: isLargeScreen ? 347 : 150,
          zIndex: 1,
          left: isLargeScreen ? -20 : 0,
          scale: isSmallScreen ? 0.6 : isMobile ? 0 : 1,
        }}>
        <div
          style={{
            width: isLargeScreen ? 347 : 150,
            height: isLargeScreen ? 80 : 40,
            paddingLeft: isLargeScreen ? 127 : 50,
          }}>
          <img src={barrier} style={{ width: isLargeScreen ? 77 : 25 }} />
        </div>
      </motion.div>

      {/* Mountain Right */}
      <motion.div
        initial="initial"
        variants={mountainAnim}
        animate={combinedControls}
        className="absolute right-0 lg:block bottom-0 pointer-events-none origin-bottom-right"
        style={{ width: isLargeScreen ? 470 : 0, scale: isSmallScreen ? 0.7 : 1 }}>
        <img src={mountainRight} />
      </motion.div>

      {/* Mountain Left */}
      <motion.div
        initial="initial"
        variants={mountainAnim}
        animate={combinedControls}
        className="absolute left-0 lg:block bottom-0 pointer-events-none origin-bottom-left"
        style={{ width: isLargeScreen ? 386 : 0, zIndex: 99, scale: isSmallScreen ? 0.7 : 1 }}>
        <img src={mountainLeft} />
      </motion.div>

      {phase === 'auth' && false ? (
        <div
          className="test pointer-events-none"
          style={{ height: '70%', width: '40%', textAign: 'center', position: 'absolute', bottom: 0 }}>
          <Lottie options={defaultOptions} height="100%" width="100%" />
        </div>
      ) : null}

      {/* Powered By Tackle */}
      <motion.div
        initial="initial"
        variants={mountainAnim}
        animate={combinedControls}
        className="absolute"
        style={{
          display: 'flex',
          alignItems: 'center',
          bottom: isLargeScreen ? 30 : 0,
          left: isLargeScreen ? 30 : 0,
          opacity: 0.7,
          zIndex: 999,
        }}>
        <a href="https://www.timetackle.com/" target="_blank" rel="noreferrer">
          <div className="font-exo2 text-white" style={{ fontSize: 12, fontWeight: 400, lineHeight: '14.4px' }}>
            Powered by
          </div>
          <div>
            <img src={tackle} style={{ height: 14, marginTop: 7 }} />
          </div>
        </a>
      </motion.div>

      <SpaceStuffs {...{ combinedControls }} />

      {/* TextBox */}
      <motion.div
        initial="initial"
        variants={textAnim}
        animate={combinedControls}
        style={{ marginTop: window.innerHeight < 900 ? 16 : 80 }}
        className="absolute w-screen top-0 px-4 text-center flex flex-col items-center">
        <img src={yimLogo} style={{ width: isSmallScreen ? 96 : 148 }} />
        <div
          style={{ fontSize: isSmallScreen ? 16 : 32, marginTop: window.innerHeight < 900 ? 12 : 28 }}
          className="text-white font-exo2 font-medium">
          Get your free end of year meeting report now
        </div>

        <div className="flex mt-4 2xl:mt-7" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
          <span>
            <a
              href="https://www.producthunt.com/posts/2021-your-year-in-meetings?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-2021-your-year-in-meetings"
              target="_blank"
              rel="noreferrer">
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=322459&theme=dark&period=daily"
                alt="2021 Your Year In Meetings 🚀 - Get your free end of year meeting report now | Product Hunt"
                style={{ height: isMobile ? 56 : isSmallScreen ? 37 : 54, marginRight: 16 }}
              />
            </a>
          </span>

          {/* Login button */}

          <div style={{ marginTop: isMobile ? 12 : 0 }}>
            <button
              onClick={() => setNewAppModalVisible(true)}
              className="shadow-md font-exo2 font-semibold flex items-center text-lg"
              style={{ color: '#425466', background: '#10253e', padding: '6px' }}>
              <span
                className="bg-white"
                style={{
                  padding: isSmallScreen ? 4 : 8,
                  width: isSmallScreen ? 24 : 42,
                  height: isSmallScreen ? 24 : 42,
                }}>
                <GoogleLogo style={{ height: isSmallScreen ? 16 : 26, width: isSmallScreen ? 16 : 26 }} />
              </span>
              <span
                style={{
                  fontSize: isSmallScreen ? 12 : 20,
                  lineHeight: '24px',
                  fontWeight: 600,
                  color: '#72feb9',
                  padding: '0px 18px 0px 24px',
                }}>
                Sign In to Launch!
              </span>
            </button>
          </div>
        </div>

        {/* Privacy Button */}
        <div
          className="underline text-white cursor-pointer font-exo2"
          style={{
            fontWeight: 600,
            fontSize: isSmallScreen ? 12 : 16,
            lineHeight: isSmallScreen ? '16px' : '22px',
            marginTop: isSmallScreen ? 4 : 28,
          }}
          onClick={() => setPrivacyModalVisible(true)}>
          Data Privacy
        </div>
      </motion.div>

      {/* {window.innerWidth >= 640 && phase === 'auth' ? (
        <div className="absolute" style={{ bottom: 30, right: 30 }}>
          <ProductHuntButton />
        </div>
      ) : null} */}

      {/* Privacy Modal */}
      <PrivacyModal {...{ privacyModalVisible, setPrivacyModalVisible }} />
      <NewAppModal {...{ newAppModalVisible, setNewAppModalVisible }} />
    </motion.div>
  );
};

export default Auth;

const textAnim = {
  initial: { opacity: 1, y: 0 },
  move: { opacity: 0, y: -50, transition: { duration: 0.5 } },
};

const groundAnim = {
  initial: { y: 0 },
  move: { y: 1000, transition: { type: 'keyframes', easings: 'easeOut', duration: 6 } },
};

const bgMountainAnim = {
  initial: { y: 0 },
  move: { y: 1000, transition: { type: 'keyframes', easings: 'easeOut', duration: 6.5 } },
};

const mountainAnim = {
  initial: { y: 0 },
  move: { y: 1000, transition: { type: 'keyframes', easings: 'easeOut', duration: 3.5 } },
};

const cloudAnim = {
  initial: { x: 40 },
  float: { x: 400, transition: { type: 'tween', ease: 'easeInOut', duration: 40, yoyo: Infinity } },
  move: { x: -800, transition: { type: 'keyframes', easings: 'easeInOut', duration: 3.5 } },
};

const cloudAnimSmall = {
  initial: { x: 0 },
  float: { x: -400, transition: { type: 'tween', ease: 'easeInOut', duration: 40, yoyo: Infinity } },
  move: { x: 800, transition: { type: 'keyframes', ease: 'easeInOut', duration: 3.5 } },
};

const containerAnim = {
  auth: { y: 0, opacity: 1 },
  exit: { opacity: 0 },
  loadingComplete: {
    opacity: 0,
    transition: {
      duration: 1,
      type: 'tween',
    },
  },
  scroll: {
    opacity: 0,
    transition: {
      duration: 1,
      type: 'tween',
    },
  },
};

const gradientAnim = {
  initial: { y: '-500vh' },
  move: { y: '-80vh', transition: { duration: 16 } },
};
