import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React, { useLayoutEffect, useMemo } from 'react';
import { isMobile } from 'utils/misc';

am4core.useTheme(am4themes_animated);
// am4core.options.queue = true;
am4core.options.animationsEnabled = false;
am4core.options.autoDispose = true;
am4core.options.minPolylineStep = 5;

const Pie = ({ data, ticks, labels, legend, dx }) => {
  const id = useMemo(() => 'chart-' + Math.random(), []);

  useLayoutEffect(() => {
    var chart = am4core.create(id, am4charts.PieChart3D);
    chart.data = data;
    chart.svgContainer.autoResize = true;
    chart.innerRadius = am4core.percent(35);
    if (dx) chart.dx = dx;

    var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'field';

    if (labels) {
      pieSeries.labels.template.fill = 'white';
    } else {
      pieSeries.labels.template.disabled = true;
    }

    pieSeries.slices.template.adapter.add('fill', function (fill, target) {
      return sliceColors[target.dataItem.index];
    });

    pieSeries.tooltip.getFillFromObject = false;
    pieSeries.tooltip.background.fill = '#fff';
    pieSeries.tooltip.background.fillOpacity = 1;
    pieSeries.tooltip.autoTextColor = false;
    pieSeries.tooltip.label.fill = '#263451';
    pieSeries.showOnInit = false;

    if (ticks) {
      pieSeries.ticks.template.stroke = '#fff';
    } else {
      pieSeries.ticks.template.disabled = true;
    }

    if (legend) {
      chart.legend = new am4charts.Legend();
      chart.legend.position = 'right';
      chart.legend.labels.template.fill = 'white';
      chart.legend.valueLabels.template.disabled = true;
      chart.legend.dx = -20;

      let marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(16, 16, 16, 16);
      marker.width = 16;
      marker.height = 16;
      marker.valign = 'middle';
    }

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: '100%', height: isMobile ? 200 : 400 }}></div>;
};

export default React.memo(Pie);

const sliceColors = ['#72FEB9', '#81CAAD', '#70B3A1', '#67A79A', '#4D8687', '#265F6B', '#202A2C', '#265F6B'];
