import Column from 'components/Charts/Column';
import Counter from 'components/Counter';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile, isSmallScreen, number } from 'utils/misc';
import Statistics from '.';
import { getMonthlyHourSpentData } from './actions';

dayjs.extend(advancedFormat);
// dayjs().format('Q Do k kk X x');

export default function MeetingHours({ isVisible = true }) {
  const { data } = useSelector((state) => state.misc);
  let chartData = getMonthlyHourSpentData(data?.monthlyHourSpent ?? {});
  const shareConfig = {
    caption: `I spent a whopping ${number(
      data?.meetingHours
    )} hours in meetings in 2021 😅. How about you? Get your report 🚀\n\n`,
    url: 'https://2021.tackle.to',
  };

  return (
    <Statistics
      title={<Counter isVisible={isVisible} to={number(data?.meetingHours)} />}
      subtitle="Total number of hours spent in meetings"
      info={
        <>
          Weekdays: Days from Monday through Friday <br />
          Meetings: Attended events with more than 1 people{' '}
        </>
      }
      chart={
        <div className="font-exo2" style={{ background: '#263D6B' }}>
          <Column data={chartData} />
        </div>
      }
      share={shareConfig}>
      <div style={{ marginTop: 24 }}>
        <div
          className="font-exo2"
          style={{
            fontWeight: 400,
            fontSize: isSmallScreen ? 16 : 18,
            lineHeight: isSmallScreen ? '20px' : '24px',
            color: '#E1E1E1',
          }}>
          <span className="capitalize font-bold">{data?.busiestMonth.toLowerCase()}</span> was the busiest month with an
          average of <span className="font-bold">{number(data?.busiestMonthAvg)}</span> hours of meeting/weekday!
        </div>
        <div
          className="font-exo2 mt-4"
          style={{ fontWeight: 400, fontSize: 16, lineHeight: isSmallScreen ? '20px' : '24px', color: '#E1E1E1' }}>
          <span className="capitalize font-bold">{dayjs(data?.highestMeeting.date).format('MMMM D')}</span> had the
          highest number of meetings - <span className="font-bold">{number(data?.highestMeeting.meetings)}</span>{' '}
          meetings for <span className="font-bold">{number(data?.highestMeeting.spent)}</span> hours.
        </div>
        {data?.busiestMonthAvg > 4.5 ? (
          <div className="flex" style={{ marginTop: isSmallScreen ? 32 : isMobile ? 12 : 60 }}>
            <span>👍</span>
            <p className="text-white ml-3">Which is comparable to a tech startup CEO.</p>
          </div>
        ) : null}
      </div>
    </Statistics>
  );
}
