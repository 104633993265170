import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import React, { useLayoutEffect, useMemo } from 'react';
import { isMobile, isSmallScreen } from 'utils/misc';

// am4core.options.queue = true;

const Column = ({ data }) => {
  const id = useMemo(() => 'chart-' + Math.random(), []);

  useLayoutEffect(() => {
    var chart = am4core.create(id, am4charts.XYChart);
    chart.data = data;
    chart.paddingTop = 37;
    chart.paddingRight = 25;
    chart.paddingBottom = 15;
    chart.paddingLeft = 15;
    chart.zoomOutButton.disabled = true;
    chart.svgContainer.autoResize = false;

    am4core.options.minPolylineStep = 5;

    const maxItem = [...data].sort((a, b) => b.value - a.value)[0];

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'month';
    categoryAxis.renderer.labels.template.fill = 'white';
    categoryAxis.renderer.grid.template.disabled = true;
    // categoryAxis.renderer.baseGrid.disabled = false;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    valueAxis.renderer.labels.template.fill = 'white';
    valueAxis.renderer.labels.template.disabled = true;
    // valueAxis.renderer.grid.template.disabled = true;

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = 'month';
    series.dataFields.valueY = 'value';
    series.columns.template.strokeOpacity = 0;
    series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';

    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = '#fff';
    series.tooltip.background.fillOpacity = 1;
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = '#263451';
    series.showOnInit = false;

    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.verticalCenter = 'bottom';
    labelBullet.label.dy = -10;
    labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.#')}";
    labelBullet.label.fill = 'white';

    labelBullet.label.adapter.add('text', (_, target) => {
      if (target._dataItem.categories.categoryX === maxItem?.month) {
        return "[bold]{values.valueY.workingValue.formatNumber('#.#')}";
      }

      return "{values.valueY.workingValue.formatNumber('#.#')}";
    });

    series.columns.template.adapter.add('fill', function (fill, target) {
      if (target.dataItem.valueY === maxItem?.value) {
        return '#72FEB9';
      }
      return 'rgba(0, 0, 0, 0.7)';
    });

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: '100%', height: isMobile ? 200 : 400 }}></div>;
};

export default React.memo(Column);
