import { googleAuthCallback } from 'actions';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

function GoogleAuthSuccessCallback(props) {
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const state = new URLSearchParams(search).get('state');

  useEffect(() => {
    const submitTokenKey = async () => {
      if (code) {
        const resp = await googleAuthCallback({
          authCode: code,
          afterAuthRedirectUrl: `${process.env.REACT_APP_AFTER_AUTH_REDIRECT_URL}`,
          type: 'GOOGLE',
        });
        if (resp) {
          localStorage.setItem('authToken', resp.jwt);
          if (window.opener) {
            window.opener.postMessage({ status: 200, message: 'success', syncId: resp.syncId, code: code, syncStatus: resp.syncStatus, respStatus: resp.status, authUrl: resp.authUrl });
            window.close();
          }
        }
      } else {
        window.close();
      }
    };
    submitTokenKey();
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        zIndex: '999999',
        position: 'fixed',
        backgroundColor: 'white',
        top: '0',
        left: '0',
      }}>
      <p style={{ textAlign: 'center' }}>Loading.....</p>
    </div>
  );
}

export default GoogleAuthSuccessCallback;
