import { ReactComponent as ArrowupIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import DeepWorkImg from 'assets/images/deep_work.png';
import Counter from 'components/Counter';
import React from 'react';
import { useSelector } from 'react-redux';
import { number } from 'utils/misc';
import Statistics from '.';
import { getDeepWorkState } from './actions';

export default function DeepWork({ isVisible = true }) {
  const { data } = useSelector((state) => state.misc);
  const deepWorkScore = number((data?.deepWorkScore * 10) / 100);
  const { messageState, fillColor } = getDeepWorkState(data);

  return (
    <Statistics
      title={
        <div className="flex items-center">
          <Counter isVisible={isVisible} to={deepWorkScore} style={{ color: fillColor }} />
        </div>
      }
      info={
        <span>
          We calculate Deep Work Score ™, considering multiple parameters, including the number, length, and time gaps
          between meetings. The score ranges from 0 to 10. The higher the number, the better!
        </span>
      }
      subtitle="Deep Work Score ™"
      chart={<img src={DeepWorkImg} />}>
      <div style={{ marginTop: 24 }}>
        <div className="font-exo2" style={{ fontWeight: 400, fontSize: 18, lineHeight: '24px', color: '#E1E1E1' }}>
          Deep Work means working in a state of deep concentration and focus for a long period, without distraction or
          interruption. If you're a maker, then deep work is synonymous to your productivity!
        </div>
      </div>
      <p className="text-white font-exo2 flex items-center" style={{ marginTop: 72 }}>
        {messageState === 'positive'
          ? '👍 Enough time for deep work'
          : messageState === 'warning'
          ? '😐 Occasionally have time for deep work'
          : '👎 Not enough time for deep work'}
      </p>
    </Statistics>
  );
}

export const getDeepScoreColor = (value) =>
  value > 65 ? '#72FEB9' : value <= 65 && value >= 40 ? '#FFA674' : '#FF6363';
