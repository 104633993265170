import 'antd/dist/antd.css';
import 'assets/css/index.css';
import Statistics from 'components/Statistics';
import Auth from 'pages/auth/index.js';
import GoogleAuthSuccessCallback from 'pages/GoogleAuthSuccessCallback.js';
import Home from 'pages/home/index.js';
import Playground from 'pages/playground.js';
import React from 'react';
import ReactDOM from 'react-dom';
import Share from 'pages/share';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import store from 'store/index.js';
import App from './App.js';
import GoogleAuthLoading from 'pages/GoogleAuthLoading.js';

require('dotenv').config();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/google-auth-success-callback" element={<GoogleAuthSuccessCallback />} />
            <Route path="/google-auth-loading" element={<GoogleAuthLoading />} />
            <Route path="/home" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/statistics" element={<Statistics />} />
            <Route path="/route99" element={<Playground />} />
            {/* <Route path="/test" element={<Test />} /> */}
            <Route path="/:shareKey" element={<Share />} />
          </Routes>
        </App>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
