export const generateAvatarFromLetter = (letter, color, textColor) => {
  return `https://ui-avatars.com/api/?background=${color.substr(1, color.length)}&color=${
    textColor ?? 'fff'
  }&name=${letter}&rounded=true`;
};

export const getScreenSize = () => {
  return window.innerWidth >= 1024 ? 'lg' : 'sm';
};

export const matchScreenSize = (size) => {
  return getScreenSize() === size;
};

export const number = (value) => {
  if (typeof value === 'number') {
    return value % 1 === 0 ? value : Number(value.toFixed(1));
  }

  return value;
};

export const meetingHealthGrade = (value) => {
  if (value >= 75) {
    return {
      grade: 'A+',
      color: '#72FEB9',
    };
  } else if (value >= 70) {
    return {
      grade: 'A',
      color: '#72FEB9',
    };
  } else if (value >= 67) {
    return {
      grade: 'A-',
      color: '#72FEB9',
    };
  } else if (value >= 64) {
    return {
      grade: 'B+',
      color: '#FEF072',
    };
  } else if (value >= 61) {
    return {
      grade: 'B',
      color: '#FEF072',
    };
  } else if (value >= 58) {
    return {
      grade: 'B-',
      color: '#FEF072',
    };
  } else if (value >= 50) {
    return {
      grade: 'C+',
      color: '#FFA674',
    };
  } else if (value >= 42) {
    return {
      grade: 'C',
      color: '#FFA674',
    };
  } else if (value >= 39) {
    return {
      grade: 'C-',
      color: '#FFA674',
    };
  } else if (value >= 36) {
    return {
      grade: 'D',
      color: '#FF6363',
    };
  } else if (value >= 33) {
    return {
      grade: 'D-',
      color: '#FF6363',
    };
  } else {
    return {
      grade: 'F',
      color: '#FF6363',
    };
  }
};

export const isLongScreen = window.innerHeight > 900;
export const isSmallScreen = window.innerHeight < 768;
export const isMobile = window.innerWidth < 640;
