import React, { useEffect, useState } from 'react';
import body from 'assets/images/rocket/body.png';
import flame from 'assets/images/rocket/flame.gif';
import smoke from 'assets/images/rocket/smoke.gif';
import head from 'assets/images/rocket/head.png';
import para from 'assets/images/rocket/parachute.png';
import { motion, useAnimation } from 'framer-motion';
import { delay } from 'utils/delay';
import { isMobile, isSmallScreen } from 'utils/misc';

const Rocket = ({ phase, parachutePhase }) => {
  const [loadingPhase, setLoadingPhase] = useState(0);
  const parachuteControls = useAnimation();
  const textControls = useAnimation();
  const lineControls = useAnimation();
  const rocketControls = useAnimation();
  const headControls = useAnimation();
  const boosterControls = useAnimation();
  const flameControls = useAnimation();

  useEffect(() => {
    const landSequence = async () => {
      rocketControls.start('land').then(() => {
        parachuteControls.start('closing');
        headControls.start('landed');
      });
    };
    const openSeq = async () => {
      parachuteControls.start('expanding').then(() => parachuteControls.start('floating'));
      headControls.stop();
      headControls.start('parachuteOpen');
      rocketControls.start('scroll');
    };
    if (parachutePhase === 'open') {
      openSeq();
    } else if (parachutePhase === 'closed') {
      parachuteControls.start('closing');
      rocketControls.start('scroll');
      headControls.start('scroll');
    } else if (parachutePhase === 'landed') {
      landSequence();
    }
  }, [parachutePhase]);

  useEffect(() => {
    if (phase !== 'loading') return;
    flameControls.start('burn');
    delay(14000).then(() => {
      boosterControls.start('detach');
      flameControls.set('initial');
    });
    delay(3000)
      .then(() => {
        setLoadingPhase(1);
      })
      .then(() => delay(7000))
      .then(() => {
        setLoadingPhase(2);
      });
  }, [phase]);

  useEffect(() => {
    rocketControls.start(phase);
    headControls.start(phase);
  }, [phase]);

  useEffect(() => {
    if (!loadingPhase) return;
    const textSequence = async () => {
      await textControls.start('show');
      return await textControls.start('hide');
    };
    const lineSequence = async () => {
      await lineControls.start('show');
      return await lineControls.start('hide');
    };
    textSequence();
    lineSequence();
  }, [loadingPhase]);

  return (
    <motion.div
      variants={rocketAnim}
      initial="initial"
      animate={rocketControls}
      className="absolute pointer-events-none origin-bottom"
      style={{
        left: isSmallScreen ? 'calc(50% - 133px)' : isMobile ? 'calc(50% - 125px)' : 'calc(50% - 141px)',
        height: 550,
        width: 200,
        scale: isSmallScreen || isMobile ? 0.6 : 1,
      }}>
      {/* Smoke */}
      <motion.img
        variants={smokeAnim}
        initial="initial"
        animate={phase}
        src={smoke}
        className="absolute"
        style={{ bottom: -65, height: 78, width: 78, left: 76 }}
      />

      {/* Flame */}
      <motion.img
        variants={flameAnim}
        initial="initial"
        animate={flameControls}
        src={flame}
        className="absolute"
        style={{ bottom: -80, height: 100, width: 100, left: 65 }}
      />

      {/* Line */}
      <motion.div
        className="absolute bg-white opacity-0 md:opacity-100"
        variants={lineAnim}
        style={{ height: 1, top: 350, left: 90 }}
        initial="initial"
        animate={lineControls}
      />

      {/* Text */}
      <motion.div
        variants={textAnim}
        initial="initial"
        animate={textControls}
        style={{ top: isMobile ? 620 : 350, left: isMobile ? 0 : 90 }}
        className="absolute flex justify-center md:justify-end h-28 w-64">
        <div className="text-white mt-2 mr-2 text-xl">{loadingPhaseTexts[loadingPhase]}</div>
      </motion.div>

      {/* Booster */}
      <motion.img
        variants={boosterAnim}
        initial="initial"
        animate={boosterControls}
        src={body}
        className="absolute"
        style={{ height: 250, width: 92, left: 68, top: 305 }}
      />

      {/* Parachute */}
      <motion.img
        variants={parachuteAnim}
        initial="initial"
        animate={parachuteControls}
        src={para}
        style={{ height: 247, width: 200, top: 0 }}
        className="absolute h-32 w-28 origin-bottom scale-0"
      />

      {/* Head */}
      <motion.img
        variants={headAnim}
        animate={headControls}
        src={head}
        style={{
          height: 58,
          width: 77,
          top: 247,
          left: 75,
        }}
        className="absolute origin-top"
      />
    </motion.div>
  );
};

export default React.memo(Rocket);

const rocketAnim = {
  initial: { bottom: isSmallScreen || isMobile ? 42 : 118 },
  auth: { bottom: isSmallScreen || isMobile ? 42 : 118 },
  loading: {
    bottom: isSmallScreen ? 150 : 300,
    transition: {
      duration: 3,
    },
  },
  loadingComplete: {
    rotate: [0, 10, 0],
    bottom: isSmallScreen || isMobile ? 200 : 300,
    left: isMobile ? '55%' : '80%',
    transition: { duration: 3 },
  },
  scroll: {
    bottom: isSmallScreen || isMobile ? 150 : 300,
    left: isMobile ? '55%' : '80%',
    transition: { duration: 3 },
  },
  land: {
    bottom: isMobile ? -120 : isSmallScreen ? -20 : -120,
    left: isMobile ? '55%' : '80%',
    transition: { duration: 10 },
  },
};

const boosterAnim = {
  initial: { opacity: 1 },
  detach: {
    top: window.innerHeight + 900,
    right: 70,
    rotate: [0, 60],
    transition: {
      duration: 2,
    },
  },
};

const smokeAnim = {
  initial: { opacity: 1 },
  auth: { opacity: 1 },
  loading: { opacity: 0, transition: { duration: 1 } },
  loadingComplete: { opacity: 0, transition: { delay: 0.5 } },
  scroll: { opacity: 0, transition: { delay: 0.5 } },
};

const flameAnim = {
  initial: { opacity: 0 },
  burn: { opacity: 1, transition: { delay: 0.5 } },
};

const parachuteAnim = {
  initial: { scale: 0 },
  expanding: {
    scale: 1,
    transition: {
      type: 'spring',
    },
  },
  floating: {
    rotate: [0, 5, -5],
    transition: {
      yoyo: Infinity,
      duration: 3,
      ease: 'linear',
    },
  },
  closing: {
    scale: 0,
  },
};

const headAnim = {
  initial: {
    rotate: 0,
  },
  auth: { rotate: 0 },
  loading: {},
  loadingComplete: {},
  scroll: {
    rotate: 0,
    y: [0, 20],
    transition: {
      ease: 'linear',
      yoyo: Infinity,
      delay: 1,
      duration: 3,
    },
  },
  parachuteOpen: {
    rotate: [0, 5, -5],
    y: 1,
    transition: {
      ease: 'linear',
      yoyo: Infinity,
      delay: 1,
      duration: 3,
    },
  },
  landed: { rotate: 0, y: 0 },
};

const textAnim = {
  initial: { opacity: 0 },
  show: { opacity: [0, 1], y: [-10, 0], transition: { delay: 0.2, duration: 0.3 } },
  hide: { opacity: 0, y: [0, -10], transition: { delay: 1 } },
};

const lineAnim = {
  initial: { width: 0 },
  show: { width: [0, 256], transition: { duration: 0.2, ease: 'easeOut' } },
  hide: { width: 0, transition: { delay: 2 } },
};

const loadingPhaseTexts = ['', 'Fetching Calendars', 'Analyzing Data'];
