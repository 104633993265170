import Pie from 'components/Charts/Pie';
import React from 'react';
import { useSelector } from 'react-redux';
import { getMeetingSizeData } from './actions';

export default function MeetingSize() {
  const { data } = useSelector((state) => state.misc);
  const { meetingSize, meetingLength } = getMeetingSizeData(data);

  return (
    <div className="grid grid-cols-12 gap-10 w-full" style={{ background: 'rgba(0, 0, 0, 0.7)', padding: 48 }}>
      <div className="col-span-6">
        <SizeChart title="Meetings By Size" data={meetingSize} />
      </div>
      <div className="col-span-6">
        <SizeChart title="Meetings By Length" data={meetingLength} />
      </div>
    </div>
  );
}

const SizeChart = ({ title, data }) => {
  return (
    <>
      <h3 className="text-white" style={{ fontWeight: 500, fontSize: 28, lineHeight: '32px' }}>
        {title}
      </h3>
      <div style={{ fontSize: 16, lineHeight: '20px', fontWeight: 500 }}>
        <Pie data={data} legend dx={-60} />
      </div>
    </>
  );
};
