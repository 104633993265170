import { Carousel } from 'antd';
import LeftChevron from 'assets/icons/left-chevron.svg';
import QuoteIcon from 'assets/icons/quote.svg';
import RightChevron from 'assets/icons/right-chevron.svg';
import PrimaryModal from 'components/PrimaryModal';
import SocialShare from 'components/SocialShare';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { number } from 'utils/misc';

export default function ShareModal({ shareVisible, setShareVisible }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slider = useRef();
  const { data } = useSelector((state) => state.misc);
  const shareURL = process.env.REACT_APP_SOCIAL_SHARE_URL + '/' + data?.shareKey + (currentSlide + 1);

  const slides = [
    {
      content: `I spent a whopping ${number(data?.meetingHours)} hours in meetings in 2021 😅`,
    },
    {
      content: `I met ${number(data?.uniquePeople.total)} awesome people in 2021 🔥`,
    },
    {
      content: `I had ${number(data?.meetings)} meetings in 2021 😬`,
    },
    {
      content: `I spent ${number(data?.workHourSpentAvg)}% of work time in meetings in 2021 😅`,
    },
  ];

  return (
    <PrimaryModal title="Share your journey" width={956} visible={shareVisible} setVisible={setShareVisible}>
      <div className="flex items-center justify-center">
        <img src={LeftChevron} alt="left chevron" className="cursor-pointer" onClick={() => slider.current.prev()} />
        <div className="w-3/4">
          <Carousel ref={slider} dots={false} className="my-10" afterChange={(current) => setCurrentSlide(current)}>
            {slides.map((slide, i) => (
              <div key={i} className="text-center">
                <div className="flex justify-center">
                  <img src={QuoteIcon} alt="quote icon" />
                  <img src={QuoteIcon} alt="quote icon" className="ml-1" />
                </div>
                <h3
                  className="text-white mt-2.5 font-exo2 w-8/12 inline-block"
                  style={{ fontSize: 40, lineHeight: '56px' }}>
                  {slide.content}
                </h3>
              </div>
            ))}
          </Carousel>
        </div>
        <img src={RightChevron} alt="right chevron" className="cursor-pointer" onClick={() => slider.current.next()} />
      </div>
      <SocialShare
        className="mt-4"
        shareURL={shareURL}
        caption={slides[currentSlide]?.content + ' How about you? Get your meeting report. Here’s mine 🚀' + '\n\n'}
      />
    </PrimaryModal>
  );
}
