import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import cloudBig from 'assets/images/cloud_big_01.png';
import starsBottom from 'assets/images/bg-stars-bottom.png';
import starsTop from 'assets/images/bg-stars-top.png';
import planet1 from 'assets/images/space/planet-1.png';
import planet2 from 'assets/images/space/planet-2.png';
import planet3 from 'assets/images/space/planet-3.png';
import planet4 from 'assets/images/space/planet-4.png';
import nebula from 'assets/images/space/nebula.png';
import cloud3 from 'assets/images/space/cloud-3.png';
import cloud4 from 'assets/images/space/cloud-4.png';
import satellite from 'assets/images/space/satellite-station.png';
import astro from 'assets/images/space/astronaut.png';
import karman from 'assets/images/space/karman-line.png';
import tesla from 'assets/images/space/tesla.png';
import Stats from '../Stats';
import { motion, useAnimation } from 'framer-motion';
import { delay } from 'utils/delay';
import Meteor from '../Meteor';

const ParallaxView = ({ phase, parallax }) => {
  const [visible, setVisible] = useState(false);
  const meteorControls = useAnimation();

  useEffect(() => {
    if (phase === 'scroll') {
      delay(600).then(() => setVisible(true));
    }
  }, [phase]);

  useEffect(() => {
    meteorControls.start('move');
  }, []);

  return (
    <Parallax enabled={true} className="lax" ref={parallax} pages={11}>
      {/* Gradient */}
      <ParallaxLayer
        className="pointer-events-none"
        offset={0}
        speed={0}
        factor={10}
        style={{ backgroundColor: '#173771' }}
      />
      <ParallaxLayer className="gradient-short" offset={10} speed={0} factor={1} />

      {/* {visible ? (
        <ParallaxLayer className="pointer-events-none" offset={0} speed={0.1} factor={9}>
          <motion.div
            className="w-screen h-full"
            style={{
              backgroundImage: `url(${starsBottom})`,
              backgroundRepeat: 'repeat',
              pointerEvents: 'none',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}></motion.div>
        </ParallaxLayer>
      ) : (
        <div />
      )} */}

      {visible ? (
        <ParallaxLayer className="pointer-events-none" offset={0} speed={0.2} factor={10}>
          <motion.div
            className="w-screen h-full"
            style={{
              backgroundImage: `url(${starsTop})`,
              backgroundRepeat: 'repeat',
              pointerEvents: 'none',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}></motion.div>
        </ParallaxLayer>
      ) : (
        <div />
      )}

      {/* Screen 1 */}
      <ParallaxLayer className="pointer-events-none" offset={0.2} speed={0}>
        <div className="absolute pointer-events-none" style={{ height: 140, width: 140, left: 200 }}>
          <img src={nebula} style={{ maxHeight: '100%' }} />
        </div>
      </ParallaxLayer>

      <ParallaxLayer className="pointer-events-none" offset={0.3} speed={0}>
        <div className="absolute pointer-events-none" style={{ height: 480, width: 480, right: -240 }}>
          <img src={planet4} style={{ maxHeight: '100%' }} />
        </div>
      </ParallaxLayer>

      {/* Screen 2 */}
      {visible ? (
        <ParallaxLayer className="pointer-events-none" offset={2} speed={0.4}>
          <div className="absolute pointer-events-none" style={{ height: 140, width: 140, right: 500 }}>
            <img src={nebula} style={{ maxHeight: '100%' }} />
          </div>
        </ParallaxLayer>
      ) : (
        <div />
      )}

      {/* {visible ? (
        <ParallaxLayer className="pointer-events-none" offset={1.7} speed={0} style={{ mixBlendMode: 'overlay' }}>
          <div className="absolute pointer-events-none" style={{ width: window.innerWidth }}>
            <img src={karman} style={{ maxWidth: '100%' }} />
          </div>
        </ParallaxLayer>
      ) : (
        <div />
      )} */}

      {/* Screen 3 */}
      <ParallaxLayer className="pointer-events-none" offset={3.1} speed={0.4}>
        <div className="absolute pointer-events-none" style={{ width: 240, left: 260 }}>
          <img src={planet3} style={{ maxHeight: '100%' }} />
        </div>
      </ParallaxLayer>

      <ParallaxLayer className="pointer-events-none" offset={2} speed={0.4}>
        <motion.div
          initial={{ rotate: -45 }}
          className="absolute pointer-events-none"
          style={{ width: 280, left: 120 }}>
          <img src={tesla} style={{ maxHeight: '100%', filter: 'grayscale(0.6)' }} />
        </motion.div>
      </ParallaxLayer>

      {/* Screen 5 */}
      <ParallaxLayer className="pointer-events-none" offset={5} speed={0.2}>
        <div className="absolute pointer-events-none" style={{ height: 200, left: 260 }}>
          <img src={planet1} style={{ maxHeight: '100%' }} />
        </div>
      </ParallaxLayer>

      <ParallaxLayer className="pointer-events-none" offset={6.5} speed={0.3}>
        <div className="absolute pointer-events-none" style={{ height: 400, right: 0 }}>
          <img src={satellite} style={{ maxHeight: '100%' }} />
        </div>
      </ParallaxLayer>

      <ParallaxLayer className="pointer-events-none" offset={6.6} speed={0.3}>
        <motion.div
          animate={{ rotate: 10, transition: { duration: 5, yoyo: Infinity } }}
          className="absolute pointer-events-none origin-top-right"
          style={{ height: 200, right: 50 }}>
          <img src={astro} style={{ maxHeight: '100%' }} />
        </motion.div>
      </ParallaxLayer>

      <Meteor />

      {visible ? <Stats /> : <div />}

      <ParallaxLayer className="pointer-events-none" offset={8.1} speed={0.5}>
        {/* <img src={cloud3} style={{ display: 'block', width: '20%', marginLeft: '70%' }} /> */}
        <img src={cloud4} style={{ display: 'block', width: '20%', marginLeft: '40%', filter: 'grayscale(50)' }} />
      </ParallaxLayer>

      {/* <ParallaxLayer className="pointer-events-none" offset={7} speed={0.3}>
        <img src={cloud3} style={{ display: 'block', width: '20%', marginLeft: '60%' }} />
        <img src={cloudBig} style={{ display: 'block', width: '25%', marginLeft: '30%' }} />
        <img src={cloud4} style={{ display: 'block', width: '30%', marginLeft: '80%' }} />
      </ParallaxLayer> */}

      <ParallaxLayer className="pointer-events-none" offset={9} speed={0.4}>
        <img src={cloudBig} style={{ display: 'block', width: '20%', marginLeft: '5%' }} />
        {/* <img src={cloud4} style={{ display: 'block', width: '15%', marginLeft: '75%' }} /> */}
      </ParallaxLayer>

      <ParallaxLayer offset={10} speed={0}>
        <Footer />
      </ParallaxLayer>
    </Parallax>
  );
};

export default React.memo(ParallaxView);
