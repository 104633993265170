import React from 'react';
function GoogleAuthLoading(props) {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        zIndex: '999999',
        position: 'fixed',
        backgroundColor: 'white',
        top: '0',
        left: '0',
      }}>
      <p style={{ textAlign: 'center' }}>Loading.....</p>
    </div>
  );
}

export default GoogleAuthLoading;
