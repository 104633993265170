import Heatmap from 'components/Charts/Heatmap';
import Counter from 'components/Counter';
import Dummy from 'components/Dummy';
import React from 'react';
import { useSelector } from 'react-redux';
import { number } from 'utils/misc';
import Statistics from '.';
import { getMeetingNumbersData, getWeekendMeetingCount } from './actions';

export default function MeetingCount({ isVisible = true }) {
  const { data } = useSelector((state) => state.misc);
  const chartData = getMeetingNumbersData(data?.hourlyStatistics || []);

  const shareConfig = {
    caption: `I had ${number(data?.meetings)} meetings in 2021 😬. How about you? Get your report 🚀\n\n`,
    url: 'https://2021.tackle.to',
  };

  return (
    <Statistics
      title={<Counter isVisible={isVisible} to={number(data?.meetings)} />}
      subtitle="Total number of meetings"
      info={
        <>
          Work hour: 9AM to 5PM ({data?.timeZoneAbbr})
          <br />
          Weekdays: Days from Monday through Friday
          <br />
          Meetings: Attended events with more than 1 people{' '}
        </>
      }
      share={shareConfig}
      chart={
        <div className="font-exo2" style={{ background: '#263D6B', padding: '0px 12px 12px 15px' }}>
          <Heatmap data={chartData} />
        </div>
      }>
      <div style={{ marginTop: 24 }}>
        <ul className="font-exo2" style={{ fontWeight: 400, fontSize: 18, lineHeight: '24px', color: '#E1E1E1' }}>
          <li className="flex items-start mb-2">
            <div className="overflow-hidden" style={{ width: 25 }}>
              🗓
            </div>
            <div className="flex-1 ml-2">
              <span className="capitalize font-bold">{data?.busiestDay.toLowerCase()}</span>&nbsp; is the busiest day{' '}
            </div>
          </li>
          <li className="flex items-start mb-2">
            <div className="overflow-hidden" style={{ width: 25 }}>
              🕜
            </div>
            <div className="flex-1 ml-2">
              <span className="font-bold">{data?.busiestHour}</span>&nbsp; <span>({data?.timeZoneAbbr})</span> is the
              busiest hour
            </div>
          </li>
          <li className="flex items-start mb-2">
            <div className="overflow-hidden" style={{ width: 25 }}>
              🌅
            </div>
            <div className="flex-1 ml-2">
              <span className="font-bold">{number(data?.outsideWorkHourMeetings)}</span>&nbsp; of these meetings were
              outside of work hours
            </div>
          </li>
          <li className="flex items-start mb-2">
            <div className="overflow-hidden" style={{ width: 25 }}>
              🏝
            </div>
            <div className="flex-1 ml-2">
              {' '}
              <span className="font-bold">{number(data?.weekendMeetings)}</span> were on weekends
            </div>
          </li>
          <li className="flex items-start mb-2">
            <div className="overflow-hidden" style={{ width: 25 }}>
              📈
            </div>
            <div className="flex-1 ml-2">
              {' '}
              <span className="font-bold">{Math.ceil(number(data?.workDayMeetingAvg))}</span> meetings on average on
              weekdays
            </div>
          </li>
        </ul>
      </div>
    </Statistics>
  );
}
