import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import React, { useLayoutEffect, useMemo } from 'react';
import { isMobile } from 'utils/misc';

am4core.addLicense('CH255140929');
// am4core.options.queue = true;
// am4core.options.onlyShowOnViewport = true;

const Heatmap = ({ data }) => {
  const id = useMemo(() => 'chart-' + Math.random(), []);

  useLayoutEffect(() => {
    var chart = am4core.create(id, am4charts.XYChart);
    chart.data = data;
    chart.svgContainer.autoResize = true;
    chart.maskBullets = false;
    chart.paddingLeft = 0;
    chart.paddingTop = 40;
    chart.paddingBottom = 0;
    chart.dy = 18;

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

    xAxis.dataFields.category = 'weekday';
    yAxis.dataFields.category = 'hour';

    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 40;
    xAxis.renderer.labels.template.fill = 'white';
    xAxis.renderer.labels.template.dy = -359;

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.inversed = true;
    yAxis.renderer.minGridDistance = 30;
    yAxis.renderer.labels.template.fill = 'white';
    yAxis.renderer.labels.template.adapter.add('disabled', (a, b) => {
      if (b._dataItem?.itemIndex % 2) return true;
      return false;
    });

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = 'weekday';
    series.dataFields.categoryY = 'hour';
    series.dataFields.value = 'value';
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 3000;
    series.showOnInit = false;

    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = '#fff';
    series.tooltip.background.fillOpacity = 1;
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = '#263451';

    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 3;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.stroke = '#263D6B';
    columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";
    columnTemplate.width = am4core.percent(100);
    columnTemplate.height = am4core.percent(100);

    series.heatRules.push({
      target: columnTemplate,
      property: 'fill',
      max: am4core.color('#72FEB9'),
      min: am4core.color('#202A2C'),
    });

    return () => {
      chart.dispose();
    };
  }, [data]);

  return data ? (
    <div id={id} style={{ width: '100%', height: isMobile ? 200 : 400 }}></div>
  ) : (
    <div style={{ width: '100%', height: isMobile ? 200 : 400, color: 'white' }}>Loading</div>
  );
};

export default React.memo(Heatmap);
