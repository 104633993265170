import React from 'react';

export default function ShareButton({ onClick, leading, extra, title, subtitle, style, shadow, shine }) {
  return (
    <div className="relative" style={{ width: 312, height: 73, ...style }} onClick={onClick}>
      <div
        className={`text-white w-full h-full flex items-center justify-between cursor-pointer relative overflow-hidden`}
        style={{ background: '#252740', padding: '0px 30px', position: 'absolute', zIndex: 5 }}>
        {shine ? <span className="shine" /> : null}
        <div className="flex items-center w-full h-full justify-between">
          <div className="flex items-center">
            {leading}
            <div className="font-exo2 ml-3">
              <span className="uppercase" style={{ fontSize: 13, fontWeight: 600, lineHeight: '19px' }}>
                {title}
              </span>
              <br />
              <span style={{ fontWeight: 700, fontSize: 19, lineHeight: '20px' }}>{subtitle}</span>
            </div>
          </div>
          {extra}
        </div>
      </div>
      {shadow ? (
        <div style={{ width: 312, height: 73, background: '#89FFC5', position: 'absolute', top: 6, left: 6 }} />
      ) : null}
    </div>
  );
}
