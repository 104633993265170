import HundredStack from 'components/Charts/HundredStack';
import Counter from 'components/Counter';
import Dummy from 'components/Dummy';
import React from 'react';
import { useSelector } from 'react-redux';
import { number } from 'utils/misc';
import Statistics from '.';
import { getMonthlyAvailableData } from './actions';

export default function MeetingStack({ isVisible = true }) {
  const { data } = useSelector((state) => state.misc);
  let chartData = getMonthlyAvailableData(data?.monthlySpentAvg ?? {});

  const shareConfig = {
    caption: `I spent ${number(
      data?.workHourSpentAvg
    )}% of work time in meetings in 2021 😅. How about you? Get your report 🚀\n\n`,
    url: 'https://2021.tackle.to',
  };

  return (
    <Statistics
      title={
        <span>
          <Counter isVisible={isVisible} to={number(data?.workHourSpentAvg)} autoWidth={true} />%
        </span>
      }
      share={shareConfig}
      subtitle="Average work hours spent in meetings"
      info={
        <>
          Work hour: 9AM to 5PM ({data?.timeZoneAbbr})<br />
          Meetings: Attended events with more than 1 people{' '}
        </>
      }
      chart={
        <div className="font-exo2" style={{ background: '#263D6B' }}>
          <HundredStack data={chartData} />
        </div>
      }>
      <div style={{ marginTop: 24 }}>
        <div className="font-exo2" style={{ fontWeight: 400, fontSize: 18, lineHeight: '24px', color: '#E1E1E1' }}>
          <span className="capitalize font-bold">{data?.busiestWorkHourMonth?.toLowerCase()}</span> was the busiest
          month with&nbsp;
          <span className="font-bold">{number(data?.busiestWorkHourMonthAvg)}</span> of work hours spent in meetings
        </div>
      </div>
    </Statistics>
  );
}
