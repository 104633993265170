import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import yimLogo from 'assets/images/logo/logo_2021.png';
import arrow from 'assets/icons/arrow-double.png';
import Counter from 'components/Counter';
import { ReactComponent as ShutDownIcon } from 'assets/icons/shutdown.svg';
import { logout } from 'utils/auth';
import { Tooltip } from 'antd';
import { isSmallScreen } from 'utils/misc';

const WIDTH = window.innerWidth;
const isLargeScreen = WIDTH > 900;
const isXLScreen = WIDTH >= 1350;

const HUD = ({ phase, showHUD, hudPhase, scrolledPercentY }) => {
  const isLoggedIn = !!localStorage.getItem('authToken');
  const [velocity, setVelocity] = useState(0);
  const [altitude, setAltitude] = useState(0);
  const [duration, setDuration] = useState(22);

  useEffect(() => {
    if (phase === 'auth') return;
    switch (phase) {
      case 'loading':
        setAltitude(278503);
        setVelocity(highestVelocity);
        break;
      case 'loadingComplete':
        setAltitude(highestPoint);
        setVelocity(0);
        setDuration(3);
        break;
      case 'scroll':
        setAltitude((highestPoint * (100 - scrolledPercentY)) / 100);
        setVelocity(parseInt((25 * (100 - scrolledPercentY)) / 100));
        setDuration(0.3);
        break;
    }
  }, [phase, scrolledPercentY]);

  return isLargeScreen ? (
    <>
      {/* HUD - Scale on the Left */}
      <motion.div
        initial={{ x: -120 }}
        animate={{ x: showHUD && phase === 'loading' ? 0 : -120 }}
        transition={{ duration: 0.2, delay: 1 }}
        className="absolute bottom-0 origin-top-left pointer-events-none"
        style={{ width: '90vh', rotate: -90, left: 16 }}>
        {/* progress */}
        <div className="mb-2">
          <div className="border opacity-50 mt-2"></div>
          <motion.div
            initial={{ width: '0vh' }}
            animate={{
              width: phase === 'loading' || phase === 'loadingComplete' ? HUDProgressMap[hudPhase] : '0vh',
              transition: { duration: 4.3, type: 'just', ease: 'linear' },
            }}
            className="absolute h-2 bg-white top-1 rounded-md"></motion.div>
        </div>

        {/* captions */}
        <div className="flex justify-between text-white font-medium font-exo2">
          {HUDLabels.map((item, i) => (
            <motion.div
              key={i}
              initial={{ backgroundColor: 'transparent', color: 'white' }}
              animate={{
                backgroundColor: hudPhase === item.phase ? '#72FEB9' : 'transparent',
                color: hudPhase === item.phase ? '#000' : '#fff',
              }}
              style={{ padding: '2px 4px', fontSize: isSmallScreen ? 12 : 18 }}>
              {item.label}
            </motion.div>
          ))}
        </div>
      </motion.div>

      {/* HUD - Altitude Meter */}
      {phase === 'loading' ? (
        <motion.div
          initial={{ y: 120 }}
          animate={{ y: showHUD ? 0 : 120 }}
          transition={{ duration: 0.2, delay: 1 }}
          className="absolute bg-red-600d w-full bottom-4 origin-top-left pointer-events-none flex items-center justify-between text-white font-semibold font-exo"
          style={{ padding: '0px 65px 0px 140px' }}>
          <div
            className="flex"
            style={{ padding: '8px 24px', background: 'rgba(6, 12, 32, 0.05)', backdropFilter: 'blur(30px)' }}>
            <div className="flex items-center">
              <div className="text-base mr-6 font-exo2 leading-[18px]">
                NEW SHEPARD
                <br />
                VELOCITY
              </div>
              <div>
                <span className="text-4xl font-bold">
                  <Counter to={velocity} duration={duration} />
                </span>
                <span className="text-base"> MPH</span>
              </div>
            </div>
            <div className="bg-white rounded-md" style={{ width: 5, margin: '0px 24px', height: 76 }}></div>
            <div className="flex items-center">
              <div className="text-base mr-6 font-exo2 leading-[18px]">
                NEW SHEPARD
                <br />
                ALTITUDE
              </div>
              <div>
                <span className="text-4xl font-bold">
                  <Counter to={altitude} duration={duration} />
                </span>
                <span className="text-base ml-2">FT</span>
              </div>
            </div>
          </div>
        </motion.div>
      ) : null}

      {/* HUD - Landing Scale */}
      {isXLScreen ? (
        <motion.div
          initial={{ x: -120 }}
          animate={{ x: phase === 'scroll' ? 0 : -120 }}
          transition={{ duration: 0.2 }}
          className="absolute bottom-0 origin-top-left pointer-events-none"
          style={{ width: '90vh', rotate: -90, left: 16 }}>
          {/* progress */}
          <div style={{ marginBottom: isSmallScreen ? 4 : 8 }}>
            <div className="border opacity-50 mt-2"></div>
            <motion.div
              initial={{ width: '90vh' }}
              animate={{
                width: `calc(90vh - ${scrolledPercentY}vh)`,
                transition: { duration: 0.3 },
              }}
              className="absolute h-2 bg-white top-1 rounded-md"></motion.div>
          </div>
          {/* captions */}
          <div className="flex justify-between text-white font-medium">
            {HUDLabelsForScroll.map((item, i) => (
              <motion.div
                key={i}
                initial={{ backgroundColor: 'transparent', color: 'white' }}
                animate={{
                  backgroundColor:
                    scrolledPercentY >= item.y[0] && scrolledPercentY < item.y[1] ? '#72FEB9' : 'transparent',
                  color: scrolledPercentY >= item.y[0] && scrolledPercentY < item.y[1] ? '#000' : '#fff',
                }}
                style={{ padding: '2px 4px', fontSize: isSmallScreen ? 12 : 18 }}>
                {item.label}
              </motion.div>
            ))}
          </div>
        </motion.div>
      ) : null}

      {/* Logo and Tagline */}
      <div style={{ opacity: phase === 'auth' ? 0 : 1 }} className="absolute right-7 top-7">
        <img src={yimLogo} style={{ width: isSmallScreen ? 80 : 120, height: isSmallScreen ? 80 : 120 }} />
      </div>

      {/* Logout */}
      {isLoggedIn ? (
        <div className="absolute right-10 bottom-10">
          <Tooltip title={<span className="text-black">Logout</span>} color="white">
            <ShutDownIcon className="cursor-pointer pointer-events-auto" onClick={logout} />
          </Tooltip>
        </div>
      ) : null}

      {/* Arrow */}
      <div
        className="absolute left-6 bottom-2"
        style={{ transform: phase === 'scroll' ? 'rotate(180deg)' : 'rotate(0deg)' }}>
        <img src={arrow} style={{ width: isSmallScreen ? 12 : 24 }} />
      </div>
    </>
  ) : null;
};

export default React.memo(HUD);

const HUDLabels = [
  { label: 'Liftoff', phase: 0 },
  { label: 'Mach 3+', phase: 1 },
  { label: 'Zero-g', phase: 2 },
  { label: 'Kármán line', phase: 3 },
  { label: 'Apogee', phase: 4 },
];

const HUDLabelsForScroll = [
  { label: 'Landing', y: [80, 101] },
  { label: 'Parachutes', y: [60, 80] },
  { label: 'Rebuckle', y: [40, 60] },
  { label: 'Karman Line', y: [20, 40] },
  { label: 'Apogee', y: [0, 20] },
];

const HUDProgressMap = ['25vh', '50vh', '75vh', '90vh', '90vh'];

const highestPoint = 351210;
const highestVelocity = 1449;
