import Pie from 'components/Charts/Pie';
import Counter from 'components/Counter';
import React from 'react';
import { useSelector } from 'react-redux';
import { number } from 'utils/misc';
import Statistics from '.';
import { getMeetingUniqueData } from './actions';

export default function MeetingUnique({ isVisible }) {
  const { data } = useSelector((state) => state.misc);
  const chartData = getMeetingUniqueData(data?.uniquePeople);

  const shareConfig = {
    caption: `I met ${number(
      data?.uniquePeople.total
    )} awesome people in 2021 🔥.  How about you? Get your report 🚀\n\n`,
    url: 'https://2021.tackle.to',
  };

  return (
    <Statistics
      title={<Counter isVisible={isVisible} to={number(data?.uniquePeople.total)} />}
      subtitle="Number of unique people met"
      info={<span>Your domain: {data?.domain}</span>}
      share={shareConfig}
      chart={
        <div className="font-exo2" style={{ background: '#263D6B' }}>
          <Pie data={chartData?.slice(1)} ticks={window.innerWidth > 1500} labels={window.innerWidth > 1500} />
        </div>
      }>
      <div style={{ marginTop: 24 }}>
        <div className="font-exo2" style={{ fontWeight: 400, fontSize: 18, lineHeight: '24px', color: '#E1E1E1' }}>
          <span className="font-bold">{number(data?.uniquePeople.ownDomain)}</span> of them are from your own domain (
          {data?.domain}) & <span className="font-bold">{number(data?.uniquePeople.externalDomain)}</span> are from
          other domains.
        </div>
      </div>
    </Statistics>
  );
}
