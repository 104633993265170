import PageLoader from 'components/PageLoader';
import Rocket3onShare from 'components/Rocket3onShare';
import { useAnimation } from 'framer-motion';
import Footer from 'pages/home/components/Footer';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { fetchShareData } from 'store/misc';
import { delay } from 'utils/delay';

export default function Index() {
  const { shareKey } = useParams();
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchShareData(shareKey));
    delay(3000).then(() => setPageLoading(false));
  }, []);

  return (
    <div>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <>
          <div className="absolute h-screen w-screen gradient-short pointer-events-none" style={{ zIndex: -90 }} />
          <Footer isVisitor />
          <Rocket3onShare />
        </>
      )}
    </div>
  );
}
