import { fetchData, serviceAxios } from 'actions';

export const SET_DATA = 'SET_DATA';
export const SET_PRIVACY_MODAL_VISIBILITY = 'SET_PRIVACY_MODAL_VISIBILITY';
export const SET_SOCIAL_MODAL_VISIBILITY = 'SET_SOCIAL_MODAL_VISIBILITY';

const initialState = {
  data: null,
  privacyModalVisible: false,
  socialModalVisible: false,
};

export default function miscReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return { ...state, data: action.payload };
    case SET_PRIVACY_MODAL_VISIBILITY:
      return { ...state, privacyModalVisible: action.payload };
    case SET_SOCIAL_MODAL_VISIBILITY:
      return { ...state, socialModalVisible: action.payload };
    default:
      return state;
  }
}

export const fetchAnalyticsData = () => {
  return async (dispatch) => {
    const data = await fetchData();

    dispatch({
      type: SET_DATA,
      payload: data,
    });
  };
};

export const fetchShareData = (shareKey) => {
  return async (dispatch) => {
    const data = (await serviceAxios.get('/yearEndReview/public', { params: { shareKey } }))?.data;

    if (data) {
      dispatch({
        type: SET_DATA,
        payload: data,
      });
    }
  };
};
