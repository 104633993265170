import { Modal } from 'antd';
import CloseIcon from 'assets/icons/close.svg';
import React from 'react';

export default function PrimaryModal({ visible, setVisible, children, title, closable = true, bodyStyle, ...rest }) {
  return (
    <Modal
      visible={visible}
      centered
      footer={null}
      closable={false}
      closeIcon={null}
      {...rest}
      className="custom-modal text-white font-primary"
      bodyStyle={{ ...style.modal, ...bodyStyle }}
      // maskStyle={{ background: '#000', opacity: '0.6' }}
      onCancel={() => setVisible?.(false)}>
      {closable ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
          <h2 className="font-exo2" style={style.title}>
            {title}
          </h2>
          <img
            src={CloseIcon}
            alt="close icon"
            style={{ width: 15, cursor: 'pointer' }}
            onClick={() => setVisible?.(false)}
          />
        </div>
      ) : null}

      <div>{children}</div>
    </Modal>
  );
}

const style = {
  modal: {
    background: 'rgba(38, 95, 107, 0.8)',
    backdropFilter: 'blur(10px)',
    padding: '32px 48px',
  },
  title: {
    fontSize: 32,
    lineHeight: '38px',
    fontWeight: 600,
    color: 'white',
  },
};
