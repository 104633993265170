import axios from 'axios';

export const serviceAxios = axios.create({
  baseURL: `https://core-dot-timetackle.ue.r.appspot.com`,
  timeout: `900000`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Expose-Headers': 'authorization, analytics-authorization, authorization',
  },
});

export const fetchData = async () => {
  try {
    // let resp = await serviceAxios.get('https://run.mocky.io/v3/9ff75a28-f491-486e-b0e3-08b38c130c63', {
    let resp = await serviceAxios.get('/yearEndReview', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    });
    return resp.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const googleAuth = async () => {
  try {
    let resp = await serviceAxios.get(
      `/account/auth?type=GOOGLE&afterAuthRedirectUrl=${process.env.REACT_APP_AFTER_AUTH_REDIRECT_URL}`
    );

    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const googleAuthCallback = async (reqBody) => {
  try {
    let resp = await serviceAxios.post(`/user/auth/callback`, reqBody);

    if (resp) {
      return resp.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const calendarSyncStatus = async (syncId) => {
  return await serviceAxios.get(`/yearEndReview/sync/status?syncId=${syncId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
};

export const calendarList = async () => {
  try {
    let resp = await serviceAxios.get('/yearEndReview/calendars', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    });
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const uploadSelectedCalendar = async (calendarList) => {
  try {
    let resp = await serviceAxios.post('/yearEndReview/sync', calendarList, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    });
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteUserReq = async () => {
  try {
    let resp = await serviceAxios.post('/yearEndReview/debug/user/drop-user', null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    });
    return resp.data;
  } catch (e) {
    console.log(e);
  }
};

export const dropUser = async () => {
  const resp = await deleteUserReq();
  if (resp) {
    localStorage.removeItem('authToken');
    window.location.reload();
  }
};
