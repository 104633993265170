import React, { useEffect } from 'react';
import body from 'assets/images/rocket/body.png';
import flame from 'assets/images/rocket/flame.gif';
import head from 'assets/images/rocket/head.png';
import para from 'assets/images/rocket/parachute.png';
import { motion, useAnimation } from 'framer-motion';
import { isMobile, isSmallScreen } from 'utils/misc';

const Rocket3onShare = () => {
  const parachuteControls = useAnimation();
  const rocketControls = useAnimation();
  const headControls = useAnimation();

  useEffect(() => {
    const landSequence = async () => {
      rocketControls.start('land').then(() => {
        parachuteControls.start('closing');
        headControls.start('landed');
      });
    };
    const openSeq = async () => {
      headControls.start('parachuteOpen');
    };
    openSeq();
    landSequence();
  }, []);

  return (
    <motion.div
      variants={rocketAnim}
      initial="initial"
      animate={rocketControls}
      className="absolute pointer-events-none origin-bottom"
      style={{ height: 305, width: 200, scale: isSmallScreen || isMobile ? 0.8 : 1 }}>
      {/* Parachute */}
      <motion.img
        variants={parachuteAnim}
        initial="initial"
        animate={parachuteControls}
        src={para}
        style={{ height: 247, width: 200, top: 0 }}
        className="absolute h-32 w-28 origin-bottom scale-0"
      />

      {/* Head */}
      <motion.img
        variants={headAnim}
        animate={headControls}
        src={head}
        style={{
          height: 58,
          width: 77,
          top: 247,
          left: 75,
        }}
        className="absolute origin-top"
      />
    </motion.div>
  );
};

export default Rocket3onShare;

const rocketAnim = {
  initial: { bottom: window.innerHeight, left: isSmallScreen ? '85%' : isMobile ? '55%' : '80%' },
  land: {
    bottom: 125,
    left: isSmallScreen ? '85%' : isMobile ? '55%' : '80%',
    transition: { duration: 10 },
  },
};

const parachuteAnim = {
  initial: { scale: 1 },
  floating: {
    rotate: [0, 5, -5],
    transition: {
      yoyo: Infinity,
      duration: 3,
      ease: 'linear',
    },
  },
  closing: {
    scale: [1, 0],
  },
};

const headAnim = {
  initial: {
    rotate: 0,
  },
  auth: {
    rotate: 0,
  },
  loading: {
    // y: [0, 10],
    // transition: {
    //   yoyo: Infinity,
    //   duration: 1,
    // },
  },
  loadingComplete: {
    // y: [0, 10],
    // transition: {
    //   yoyo: Infinity,
    //   duration: 1,
    // },
  },
  scroll: {
    rotate: 0,
    // y: [0, 10],
    // transition: {
    //   yoyo: Infinity,
    //   duration: 1,
    // },
  },
  parachuteOpen: {
    rotate: [0, 5, -5],
    transition: {
      ease: 'linear',
      yoyo: Infinity,
      delay: 1,
      duration: 3,
    },
  },
  landed: { rotate: 0, y: 0 },
};
