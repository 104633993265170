import { ParallaxLayer } from '@react-spring/parallax';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import meteor from 'assets/images/space/meteor.png';
import { delay } from 'utils/delay';

const Meteor = ({ offset }) => {
  const meteorControls = useAnimation();
  const [posX, setX] = useState(0);

  useEffect(() => {
    meteorControls.start({
      y: [-60, window.innerHeight + 150],
      x: [posX, -window.innerWidth - 180],
      transition: { duration: 6, delay: 15 },
    });
    delay(45000).then(() => setX(Math.floor(-Math.random() * 1000 + 1)));
  }, [posX]);

  return (
    <ParallaxLayer offset={offset} speed={0}>
      <motion.div
        initial="initial"
        variants={meteorAnim}
        className="absolute right-0"
        animate={meteorControls}
        style={{ width: 80, height: 60 }}>
        <img src={meteor} style={{ width: '100%', maxHeight: '100%' }} />
      </motion.div>
    </ParallaxLayer>
  );
};

export default React.memo(Meteor);

const meteorAnim = {
  initial: { y: -80, x: -80 },
  move: {
    y: window.innerHeight + 150,
    x: -window.innerWidth - 180,
    transition: { type: 'tween', ease: 'linear', duration: 5, repeatDelay: 10, repeat: Infinity },
  },
};
