import React, { useEffect } from 'react';
import head from 'assets/images/rocket/head.png';
import para from 'assets/images/rocket/parachute.png';
import { motion, useAnimation } from 'framer-motion';
import { isMobile, isSmallScreen } from 'utils/misc';

const Rocket2 = ({ phase, parachutePhase }) => {
  const parachuteControls = useAnimation();
  const rocketControls = useAnimation();
  const headControls = useAnimation();

  useEffect(() => {
    const landSequence = async () => {
      rocketControls.start('land').then(() => {
        parachuteControls.start('closing');
        headControls.start('landed');
      });
    };
    const openSeq = async () => {
      parachuteControls.start('expanding').then(() => parachuteControls.start('floating'));
      headControls.stop();
      headControls.start('parachuteOpen');
      rocketControls.start('scroll');
    };
    if (parachutePhase === 'open') {
      openSeq();
    } else if (parachutePhase === 'closed') {
      parachuteControls.start('closing');
      rocketControls.start('scroll');
      headControls.start('scroll');
    } else if (parachutePhase === 'landed') {
      landSequence();
    }
  }, [parachutePhase]);

  useEffect(() => {
    rocketControls.start(phase);
    headControls.start(phase);
  }, [phase]);

  return (
    <motion.div
      variants={rocketAnim}
      initial="initial"
      animate={rocketControls}
      className="absolute pointer-events-none"
      style={{ height: 550, width: 200, scale: isSmallScreen || isMobile ? 0.8 : 1 }}>
      {/* Parachute */}
      <motion.img
        variants={parachuteAnim}
        initial="initial"
        animate={parachuteControls}
        src={para}
        style={{ height: 247, width: 200, top: 0 }}
        className="absolute h-32 w-28 origin-bottom scale-0"
      />

      {/* Head */}
      <motion.img
        variants={headAnim}
        animate={headControls}
        src={head}
        style={{
          height: 58,
          width: 77,
          top: 247,
          left: 75,
        }}
        className="absolute origin-top"
      />
    </motion.div>
  );
};

export default React.memo(Rocket2);

const rocketAnim = {
  initial: {
    bottom: isSmallScreen ? 20 : 300,
    left: isMobile ? '55%' : '80%',
    transition: { duration: 3 },
  },
  scroll: {
    bottom: isSmallScreen || isMobile ? 20 : 300,
    left: isMobile ? '55%' : '80%',
    transition: { duration: 3 },
  },
  land: {
    bottom: isMobile ? -250 : isSmallScreen ? -125 : -120,
    left: isMobile ? '55%' : '80%',
    transition: { duration: 10 },
  },
};

const parachuteAnim = {
  initial: { scale: 0 },
  expanding: {
    scale: 1,
    transition: {
      type: 'spring',
    },
  },
  floating: {
    rotate: [0, 5, -5],
    transition: {
      yoyo: Infinity,
      duration: 3,
      ease: 'linear',
    },
  },
  closing: {
    scale: 0,
  },
};

const headAnim = {
  initial: {
    rotate: 0,
  },
  auth: { rotate: 0 },
  loading: {},
  loadingComplete: {},
  scroll: {
    rotate: 0,
    y: [0, 20],
    transition: {
      ease: 'linear',
      yoyo: Infinity,
      delay: 1,
      duration: 3,
    },
  },
  parachuteOpen: {
    rotate: [0, 5, -5],
    y: 1,
    transition: {
      ease: 'linear',
      yoyo: Infinity,
      delay: 1,
      duration: 3,
    },
  },
  landed: { rotate: 0, y: 0 },
};
