import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import MeetingFatigueImg from 'assets/images/meeting_fatigue.png';
import { ReactComponent as ArrowupIcon } from 'assets/icons/arrow-up.svg';
import Counter from 'components/Counter';
import React from 'react';
import { useSelector } from 'react-redux';
import { number } from 'utils/misc';
import Statistics from '.';
import Area from 'components/Charts/Area';
import { getRecurringMeetingData } from './actions';

export default function MeetingRecurring({ isVisible = true }) {
  const { data } = useSelector((state) => state.misc);
  const chartData = getRecurringMeetingData(data);
  let recurring = 0,
    nonRecurring = 0;

  if (chartData) {
    chartData.forEach((data) => {
      recurring += data.value2;
      nonRecurring += data.value1;
    });
  }

  let recurringPercentage = (recurring * 100) / (recurring + nonRecurring);

  const shareConfig = {
    caption: `${number(recurringPercentage)} of my meetings are recurring 😅. How about you? Get your report 🚀\n\n`,
    url: 'https://2021.tackle.to',
  };

  return (
    <Statistics
      title={
        <span>
          <Counter isVisible={isVisible} to={number(recurringPercentage)} autoWidth />%
        </span>
      }
      share={shareConfig}
      subtitle={'Recurring meetings'}
      chart={
        <div className="font-exo2" style={{ background: '#263D6B', padding: '12px 12px 12px 15px' }}>
          <Area data={chartData} />
        </div>
      }>
      <div style={{ marginTop: 24 }}>
        <div className="font-exo2" style={{ fontWeight: 400, fontSize: 20, lineHeight: '26px', color: '#E1E1E1' }}>
          💁&nbsp; Recurring meetings usually are a huge time sink. Consider changing frequency and length of the these
          meetings. Also, make sure you've the right attendees, mark people as optional if they're not needed.
        </div>
      </div>
    </Statistics>
  );
}
