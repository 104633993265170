import LoadingRocket from 'assets/images/rocket-v.gif';
import React, { useEffect, useState } from 'react';

const PageLoader = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    if (count < 6) {
      setTimeout(() => {
        setCount(count + 1);
      }, 500);
    } else {
      setCount(2);
    }
  }, [count]);

  return (
    <div
      className="absolute h-screen w-screen flex flex-col justify-center items-center"
      style={{ zIndex: 999, background: '#082862' }}>
      <img src={LoadingRocket} alt="loading icon" style={{ width: 160, height: 160 }} />
      <span className="text-white" style={{ fontSize: 20, fontWeight: 600 }}>
        Loading
        {Array(count)
          .fill()
          .map(() => '.')}
      </span>
    </div>
  );
};

export default PageLoader;
