export const screens = ['landing', 'loading'];

export const months = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export const weekMap = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export const domainTypeMap = {
  total: 'Total',
  ownDomain: 'Own Domain',
  externalDomain: 'Other Domains',
};

export const COLORS = {
  success: '#52c41a',
  primary: '#2C50FF',
  lightBlue: '#b8dbf2',
  red: '#cf3a2f',
  danger: '#F11616',
  darkgray: '#888',
  grayBlue: '#607080',
  almostBlack: '#333',
  offBlack: '#0A2540',
  darkBlue: '#011528',
  lightGray: '#ACACAC',
  warning: '#FAAD14',
};

export const meetingSizeMap = {
  MORE_THAN_8: 'More than 8',
  '1_ON_1': '1 on 1',
  '3_OR_FEWER': '3 or fewer',
  '5_OR_FEWER': '5 or fewer',
  '8_OR_FEWER': '8 or fewer',
  '30_MINS_OR_LESS': '30 mins or less',
  '30_MINS_TO_1_HR': '~1 hour',
  MORE_THAN_1_HR: 'More than 1 hour',
};

export const launchpadBulbColors = ['#F90000', '#F98B00'];

export const colors = {
  success: '#72FEB9',
  warning: '#FFA674',
  danger: '#FF6363',
};
