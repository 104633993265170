import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import React, { useLayoutEffect, useMemo } from 'react';
import { isMobile } from 'utils/misc';

am4core.options.queue = true;
// am4core.options.onlyShowOnViewport = true;

const HundredStack = ({ data }) => {
  const id = useMemo(() => 'chart-' + Math.random(), []);

  useLayoutEffect(() => {
    var chart = am4core.create(id, am4charts.XYChart);

    chart.data = data;
    chart.colors.step = 2;
    chart.padding(30, 30, 10, -15);

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.fill = 'white';

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;

    var series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(80);
    series1.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.')}%";
    series1.name = 'Time Spent';
    series1.dataFields.categoryX = 'category';
    series1.dataFields.valueY = 'value1';
    series1.dataFields.valueYShow = 'totalPercent';
    series1.dataItems.template.locations.categoryX = 0.5;
    series1.stacked = true;
    series1.tooltip.pointerOrientation = 'vertical';
    series1.columns.template.strokeWidth = 0;

    series1.tooltip.getFillFromObject = false;
    series1.tooltip.background.fill = '#fff';
    series1.tooltip.background.fillOpacity = 1;
    series1.tooltip.autoTextColor = false;
    series1.tooltip.label.fill = '#263451';

    var bullet1 = series1.bullets.push(new am4charts.LabelBullet());
    bullet1.interactionsEnabled = false;
    bullet1.label.text = "{valueY.totalPercent.formatNumber('#.')}%";
    bullet1.label.fill = am4core.color('black');
    bullet1.locationY = 0.5;
    bullet1.rotation = 270;
    bullet1.label.fontWeight = 600;
    bullet1.label.truncate = false;

    bullet1.label.adapter.add('opacity', (_, target) => {
      if (target.currentText === '0%') {
        return 0;
      }
    });

    var series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.columns.template.width = am4core.percent(80);
    series2.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.')}%";
    series2.name = 'Time Available';
    series2.dataFields.categoryX = 'category';
    series2.dataFields.valueY = 'value2';
    series2.dataFields.valueYShow = 'totalPercent';
    series2.dataItems.template.locations.categoryX = 0.5;
    series2.stacked = true;
    series2.tooltip.pointerOrientation = 'vertical';
    series2.tooltip.label.fill = am4core.color('black');
    series2.columns.template.strokeWidth = 0;
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.background.fill = '#fff';
    series2.tooltip.background.fillOpacity = 1;
    series2.tooltip.autoTextColor = false;
    series2.tooltip.label.fill = '#263451';

    // var bullet2 = series2.bullets.push(new am4charts.LabelBullet());
    // bullet2.interactionsEnabled = false;
    // bullet2.label.text = "{valueY.totalPercent.formatNumber('#.')}%";
    // bullet2.locationY = 0.5;
    // bullet2.label.fill = am4core.color('#ffffff');
    // bullet2.label.fontWeight = 600;

    // bullet2.label.adapter.add('opacity', (_, target) => {
    //   if (target.currentText === '0%') {
    //     return 0;
    //   }
    // });

    series1.columns.template.adapter.add('fill', function (fill, target) {
      return '#72FEB9';
    });

    series2.columns.template.adapter.add('fill', function (fill, target) {
      return 'rgba(0, 0, 0, 0.7)';
    });

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: '100%', height: isMobile ? 200 : 400 }}></div>;
};

export default React.memo(HundredStack);
