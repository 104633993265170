import React, { useState } from 'react';
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { Tooltip } from 'antd';

export default function SocialShare({ shareURL, caption, className }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(shareURL);
    setIsCopied(true);
  };

  return (
    <div className={`flex items-center justify-center ${className}`}>
      <TwitterShareButton title={caption} url={shareURL} className="mr-3">
        <SocialButton icon={<TwitterIcon fill="#000000D9" />} text="Twitter" color="#1DA1F2" />
      </TwitterShareButton>
      <FacebookShareButton quote={caption} url={shareURL} className="mr-3">
        <SocialButton
          icon={<FacebookIcon fill="#000000D9" />}
          text={<span className="relative top-0.5">Facebook</span>}
          color="#1877F2"
        />
      </FacebookShareButton>
      <LinkedinShareButton title={caption} url={shareURL} className="mr-3">
        <SocialButton
          icon={<LinkedinIcon fill="#000000D9" />}
          text={<span className="relative top-0.5">LinkedIn</span>}
          color="#0073B1"
        />
      </LinkedinShareButton>

      <Tooltip title={isCopied ? 'Copied' : 'Copy to clipboard'}>
        <div onClick={handleCopy} onMouseLeave={() => setTimeout(() => setIsCopied(false), 500)}>
          <SocialButton
            icon={<CopyIcon fill="#000000D9" />}
            text={<span className="relative top-0.5">Copy Link</span>}
            color="#0073B1"
            className="cursor-pointer"
          />
        </div>
      </Tooltip>
    </div>
  );
}

export const SocialButton = ({ icon, text, className, type = 'solid' }) => {
  const buttonStyle = {
    padding: type === 'outline' ? '3px 0px' : '4px 16px',
    color: type === 'outline' ? '#72FEB9' : '#000000D9',
    display: 'inline-flex',
    alignItems: type === 'outline' ? 'center' : 'center',
    background: type === 'outline' ? 'transparent' : '#72FEB9',
  };

  return (
    <div className={`w-full ${className}`} style={buttonStyle}>
      {icon}
      <span className="ml-2 font-semibold" style={{ fontSize: 16, lineHeight: '32px' }}>
        {text}
      </span>
    </div>
  );
};

export const SocialButtonOutlined = ({ icon, text, className, type = 'solid' }) => {
  const buttonStyle = {
    padding: '6px 10px',
    color: '#72FEB9',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    border: '1px solid transparent',
  };

  return (
    <div className={`w-full ${className}`} style={buttonStyle}>
      {icon}
      <span className="ml-2 font-semibold font-exo2" style={{ fontSize: 16, lineHeight: '32px' }}>
        {text}
      </span>
    </div>
  );
};
