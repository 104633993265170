import {
  calendarList,
  calendarSyncStatus,
  deleteUserReq,
  fetchData,
  googleAuth,
  uploadSelectedCalendar,
} from 'actions';
import CalendarImport from 'components/CalendarImport';
import CalendarPermission from 'components/CalendarPermission/CalendarPermission';
import PageLoader from 'components/PageLoader';
import { PopupWindow, PopupWindowWithUrl } from 'components/PopupWindow';
import PrimaryModal from 'components/PrimaryModal';
import Rocket from 'components/Rocket';
import Rocket2 from 'components/Rocket2';
import ScrollBottom from 'components/ScrollBottom';
import { useAnimation } from 'framer-motion';
import Auth from 'pages/auth';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAnalyticsData, SET_SOCIAL_MODAL_VISIBILITY } from 'store/misc';
import { logout } from 'utils/auth';
import { delay } from 'utils/delay';
import { matchScreenSize } from 'utils/misc';
import HUD from './components/HUD';
import ParallaxView from './components/ParallaxView';
import Stars from './components/Stars';
import RocketGIF from 'assets/images/rocket.gif';
import storage from 'utils/storage';

const isSmallDevice = window.innerWidth < 640;

const Home = () => {
  /** States */
  const [phase, setPhase] = useState('auth');
  const [pageLoading, setPageLoading] = useState(true);
  const [loadingPhase, setLoadingPhase] = useState(0);
  const [hudPhase, setHudPhase] = useState(0);
  const [parachutePhase, setParachutePhase] = useState('initial');
  const [syncId, setSyncId] = useState(null);
  const [syncStatus, setSyncStatus] = useState(null);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [loggedInOnFirstRender, setLoggedInOnFirstRender] = useState(!!localStorage.getItem('authToken'));
  const [timestampOfLastOpen, setTimestampOfLastOpen] = useState();
  const [showHUD, setShowHUD] = useState(false);
  const [scrolledPercentY, setScrolledY] = useState(0);
  const [permissionModalVisible, setPermissionModalVisible] = useState(false);
  const [authUrl, setAuthUrl] = useState(null);
  const [starsVisible, setStarsVisible] = useState(true);
  const [smallDeviceInfoVisible, setSmallDeviceInfoVisible] = useState(isSmallDevice);
  const [scrollBottomVisible, setScrollBottomVisible] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [stat, setStat] = useState(1);

  /** Hooks */
  const dispatch = useDispatch();
  const parallax = useRef();
  const shadowControls = useAnimation();
  let intervalId = 0;

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const waitForDataFetch = async () => {
      try {
        const data = await fetchData();
        // document.querySelector('div.lax').scrollTop = 10;
        if (data) {
          dispatch({
            type: 'SET_DATA',
            payload: data,
          });
          setPhase('scroll');
          setShowHUD(true);
          setStarsVisible(false);
          setPageLoading(false);
        }
      } catch (e) {
        setLoggedInOnFirstRender(false);
        parallax.current.scrollTo(1);
      } finally {
        delay(500).then(() => setPageLoading(false));
      }
    };
    if (!authToken) {
      parallax.current.scrollTo(1);
      delay(1500).then(() => setPageLoading(false));
      return;
    } else {
      waitForDataFetch();
    }
  }, []);

  // useEffect(() => {
  //   const scroller = (event) => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     let scrollable = document.querySelector('div.lax');
  //     switch (event.deltaMode) {
  //       case 0: //DOM_DELTA_PIXEL		Chrome
  //         scrollable.scrollTop += event.deltaY;
  //         scrollable.scrollLeft += event.deltaX;
  //         scrollable.scrollLeft += event.deltaX;
  //         break;
  //       case 1: //DOM_DELTA_LINE		Firefox
  //         scrollable.scrollTop += 15 * event.deltaY;
  //         scrollable.scrollLeft += 15 * event.deltaX;
  //         break;
  //       case 2: //DOM_DELTA_PAGE
  //         scrollable.scrollTop += 0.03 * event.deltaY;
  //         scrollable.scrollLeft += 0.03 * event.deltaX;
  //         break;
  //     }
  //   };
  //   document.getElementById('overlay').onwheel = scroller;
  //   document.getElementById('overlay').ontouchmove = scroller;
  // }, []);

  useEffect(() => {
    const handleScroll = (e) => {
      if (phase !== 'scroll') return;
      const totalScrollHeight = e.target.scrollHeight - window.innerHeight;
      const scrolled = e.target.scrollTop;
      const scrollPercent = (scrolled / totalScrollHeight) * 100;

      if (scrollPercent > 98) {
        setParachutePhase('landed');
      } else if (scrollPercent > 75) {
        if (
          (parachutePhase === 'landed' && e.timeStamp - timestampOfLastOpen > 10000) ||
          parachutePhase === 'closed' ||
          parachutePhase === 'initial'
        ) {
          setTimestampOfLastOpen(e.timeStamp);
          setParachutePhase('open');
        }
      } else if (parachutePhase === 'open' || parachutePhase === 'landed') {
        setParachutePhase('closed');
      }

      if (scrollPercent > 98) {
        setShowHUD(false);
      } else {
        setShowHUD(true);
      }

      if (scrollPercent >= 0 && scrollPercent <= 10) {
        setScrolledY(0);
      } else if (scrollPercent > 10 && scrollPercent <= 20) {
        setScrolledY(15);
      } else if (scrollPercent > 20 && scrollPercent <= 30) {
        setScrolledY(25);
      } else if (scrollPercent > 30 && scrollPercent <= 40) {
        setScrolledY(35);
      } else if (scrollPercent > 40 && scrollPercent <= 50) {
        setScrolledY(45);
      } else if (scrollPercent > 50 && scrollPercent <= 60) {
        setScrolledY(55);
      } else if (scrollPercent > 60 && scrollPercent <= 70) {
        setScrolledY(65);
      } else if (scrollPercent > 70 && scrollPercent <= 80) {
        setScrolledY(75);
      } else {
        setScrolledY(100);
      }
    };

    if (phase === 'scroll') {
      setTimeout(() => setScrollBottomVisible(true), 10000);
    }

    document.querySelector('div.lax').addEventListener('scroll', handleScroll);
    return () => document.querySelector('div.lax').removeEventListener('scroll', handleScroll);
  }, [phase, parachutePhase]);

  // useEffect(() => {
  //   delay(4000).then(() => {
  //     setPhase('loading');
  //   });
  // }, []);

  useEffect(() => {
    switch (phase) {
      case 'loading':
        setShowHUD(true);
        delay(4000)
          .then(() => {
            setHudPhase(1);
            // setLoadingPhase(1);
          })
          .then(() => delay(4000))
          .then(() => setHudPhase(2))
          .then(() => delay(4000))
          .then(() => setHudPhase(3))
          .then(() => delay(4000))
          .then(() => {
            setHudPhase(4);
            setLoadingPhase(3);
          });
        break;
      case 'loadingComplete':
        delay(3000).then(() => {
          setPhase('scroll');
          parallax.current.scrollTo(0);
        });
        delay(4000).then(() => {
          setStarsVisible(false);
        });
        break;
    }
  }, [phase]);

  useEffect(() => {
    if (syncStatus === 'DONE' && loadingPhase === 3) {
      delay(3000).then(() => setPhase('loadingComplete'));
    }
  }, [syncStatus, loadingPhase]);

  useEffect(() => {
    if (syncId && syncStatus) {
      if (syncStatus === 'DONE') {
        dispatch(fetchAnalyticsData());
      } else {
        intervalId = setInterval(() => {
          fetchSyncData();
        }, 5000);
      }
    } else if (syncStatus === 'DONE') {
      dispatch(fetchAnalyticsData());
    }
  }, [syncId, syncStatus]);

  const fetchSyncData = async () => {
    try {
      let res = await calendarSyncStatus(syncId);
      if (res) {
        let result = res.data;
        if (result.state === 'IN_PROGRESS') {
          // console.log('hmmm')
          setSyncStatus('IN_PROGRESS');
        } else if (result.state === 'DONE') {
          setSyncStatus('DONE');
          // dispatch(fetchAnalyticsData());
          clearInterval(intervalId);
        } else {
          setPhase('auth');
          clearInterval(intervalId);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const shouldShowPopup = parachutePhase === 'landed' && !JSON.parse(localStorage.getItem('socialPopUp'));

    if (shouldShowPopup) {
      setTimeout(() => {
        dispatch({
          type: SET_SOCIAL_MODAL_VISIBILITY,
          payload: true,
        });
        storage.set('socialPopUp', true);
      }, 10000);
    }
  }, [parachutePhase]);

  const loginHandler = async () => {
    let windowForLogin = PopupWindow(process.env.REACT_APP_GOOGLE_AUTH_LOADING, 'Auth', authSuccessHandler);
    const resp = await googleAuth();
    if (resp) {
      loginPopupHandler(windowForLogin, resp);
    }
  };

  const loginPopupHandler = async (windowForLogin, url) => {
    PopupWindowWithUrl(windowForLogin, url);
  };

  const loginPopUpForPermission = async (url) => {
    PopupWindow(url, 'Auth', authSuccessHandler);
  };

  useEffect(() => {
    if (parachutePhase === 'open') {
      shadowControls.start('expanding');
    } else {
      shadowControls.set('initial');
    }
  }, [parachutePhase]);

  const authSuccessHandler = async (resp) => {
    if (resp && resp.data && resp.data.status === 200) {
      try {
        if (resp.data.respStatus) {
          setSyncStatus(resp.data.syncStatus);

          if (resp.data.syncStatus === 'PENDING') {
            setImportModalVisible(true);
            fetchCalendars();
          } else {
            setPhase('loading');
          }
        } else {
          setAuthUrl(resp.data.authUrl);
          setPermissionModalVisible(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const requestPermission = async () => {
    loginPopUpForPermission(authUrl);
    setPermissionModalVisible(false);
  };

  const fetchCalendars = async () => {
    const resp = await calendarList();
    if (resp) {
      let tempCalendarList = resp.sort((a, b) => {
        if (a.primary === true) return -1;
        return 0;
      });
      let primaryCalendar = tempCalendarList.find((calendar) => calendar.primary === true);
      if (primaryCalendar) {
        setSelectedCalendars([primaryCalendar.calendarId]);
      }
      setCalendars(tempCalendarList);
    }
  };

  const importCalendarHandler = async () => {
    try {
      const resp = await uploadSelectedCalendar(selectedCalendars);
      setPhase('loading');
      setSyncId(resp);
      setImportModalVisible(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (parachutePhase === 'open') {
      shadowControls.start('expanding');
    } else {
      shadowControls.set('initial');
    }
  }, [parachutePhase]);

  return (
    <div>
      <div className="absolute h-screen w-screen overflow-hidden">
        <ParallaxView {...{ phase, parallax }} />

        {starsVisible ? <Auth {...{ phase, loginHandler }} /> : null}

        {/* Stars */}
        {starsVisible ? <Stars {...{ phase }} /> : null}

        {/* HUD  - Scale */}
        {/* // width >=1352 || 1512 */}
        <HUD {...{ phase, showHUD, hudPhase, scrolledPercentY }} />
        {/* {window.innerWidth >= 1350 ? <HUD {...{ phase, showHUD, hudPhase, scrolledPercentY }} /> : null} */}

        {loggedInOnFirstRender ? <Rocket2 {...{ phase, parachutePhase }} /> : <Rocket {...{ phase, parachutePhase }} />}
        {/* <Rocket {...{ phase, parachutePhase }} /> */}

        {pageLoading ? <PageLoader /> : null}

        {matchScreenSize('sm') ? null : showHUD && scrolledPercentY < 100 ? (
          <ScrollBottom isVisible={phase === 'scroll' && scrollBottomVisible} next={null} />
        ) : null}

        <CalendarImport
          importModalVisible={importModalVisible}
          setImportModalVisible={setImportModalVisible}
          calendars={calendars}
          setSelectedCalendars={setSelectedCalendars}
          selectedList={selectedCalendars}
          importCalendarHandler={importCalendarHandler}
        />

        <CalendarPermission permissionModalVisible={permissionModalVisible} requestPermission={requestPermission} />

        <PrimaryModal visible={smallDeviceInfoVisible} setVisible={setSmallDeviceInfoVisible} closable={false}>
          <div className="text-center" style={{ fontWeight: 500, fontSize: 22, lineHeight: '30px' }}>
            <img src={RocketGIF} alt="rocket" style={{ width: 46, margin: '0px auto 16px' }} />
            For the best experience please use a <span className="font-bold">desktop</span> browser.
          </div>

          <div className="text-center">
            <button
              style={{
                background: '#72FEB9',
                fontWeight: 600,
                fontSize: 16,
                lineHeight: '32px',
                margin: '36px auto 0px',
                padding: '4px 16px',
                color: 'black',
              }}
              onClick={() => setSmallDeviceInfoVisible(false)}>
              Okay
            </button>
          </div>
        </PrimaryModal>
      </div>
    </div>
  );
};

export default Home;
