import { motion } from 'framer-motion';
import React from 'react';
import MeetingCount from 'components/Statistics/MeetingCount';
import MeetingHours from 'components/Statistics/MeetingHours';
import MeetingPeople from 'components/Statistics/MeetingPeople';
import MeetingUnique from 'components/Statistics/MeetingUnique';
import MeetingHealth from 'components/Statistics/MeetingHealth';
import MeetingFatigue from 'components/Statistics/MeetingFatigue';
import DeepWork from 'components/Statistics/DeepWork';
import MeetingStack from 'components/Statistics/MeetingStack';
import { isMobile, isSmallScreen, matchScreenSize } from 'utils/misc';
import { ParallaxLayer } from '@react-spring/parallax';
import MeetingRecurring from 'components/Statistics/MeetingRecurring';
import MeetingSize from 'components/Statistics/MeetingSize';
import ScrollBottom from 'components/ScrollBottom';

const WIDTH = window.innerWidth;
const isLargeScreen = WIDTH > 900;

const Stats = () => {
  return (
    <ParallaxLayer>
      {/* Hours */}
      <ParallaxLayer offset={0} speed={0}>
        <div
          // variants={statAnim}
          // initial="hidden"
          // animate="show"
          className="absolute h-screen flex items-center"
          style={{
            // pointerEvents: stat === 1 && isLargeScreen ? 'auto' : 'none',
            ...getStatCardStyle(),
          }}>
          <MeetingHours />
        </div>
      </ParallaxLayer>

      {/* Count */}
      <ParallaxLayer offset={1} speed={0}>
        <motion.div
          variants={statAnim}
          initial="hidden"
          animate="show"
          className="absolute h-screen flex items-center"
          style={{
            pointerEvents: isLargeScreen ? 'auto' : 'none',
            ...getStatCardStyle(),
          }}>
          <MeetingCount />
        </motion.div>
      </ParallaxLayer>

      {/* Unique */}
      <ParallaxLayer offset={2} speed={0}>
        <div
          className="absolute h-screen flex items-center"
          style={{
            pointerEvents: isLargeScreen ? 'auto' : 'none',
            ...getStatCardStyle(),
          }}>
          <MeetingUnique />
        </div>
      </ParallaxLayer>

      {/* People */}
      <ParallaxLayer offset={3} speed={0}>
        <div
          className="absolute w-screen h-screen flex items-center"
          style={{ pointerEvents: isLargeScreen ? 'auto' : 'none', ...getStatCardStyle() }}>
          <MeetingPeople />
        </div>
      </ParallaxLayer>

      {/* <ParallaxLayer offset={3} speed={0}>
        <motion.div
          className="absolute w-screen h-screen flex items-center"
          style={{ pointerEvents: isLargeScreen ? 'auto' : 'none', ...getStatCardStyle() }}>
          <MeetingSize />
        </motion.div>
      </ParallaxLayer> */}

      {/* Stack */}
      <ParallaxLayer offset={4} speed={0}>
        <div
          className="absolute w-screen h-screen flex items-center"
          style={{ pointerEvents: isLargeScreen ? 'auto' : 'none', ...getStatCardStyle() }}>
          <MeetingStack />
        </div>
      </ParallaxLayer>

      {/* Recurring */}
      <ParallaxLayer offset={5} speed={0}>
        <div
          className="absolute w-screen h-screen flex items-center"
          style={{ pointerEvents: isLargeScreen ? 'auto' : 'none', ...getStatCardStyle() }}>
          <MeetingRecurring />
        </div>
      </ParallaxLayer>

      {/* Deep work */}
      <ParallaxLayer offset={6} speed={0}>
        <div
          className="absolute w-screen h-screen flex items-center"
          style={{ pointerEvents: isLargeScreen ? 'auto' : 'none', ...getStatCardStyle() }}>
          <DeepWork />
        </div>
      </ParallaxLayer>

      {/* Health */}
      <ParallaxLayer offset={7} speed={0}>
        <div
          className="absolute w-screen h-screen flex items-center"
          style={{ pointerEvents: isLargeScreen ? 'auto' : 'none', ...getStatCardStyle() }}>
          <MeetingFatigue />
        </div>
      </ParallaxLayer>

      {/* Grade */}
      <ParallaxLayer offset={8} speed={0}>
        <div
          className="absolute w-screen h-screen flex items-center"
          style={{ pointerEvents: isLargeScreen ? 'auto' : 'none', ...getStatCardStyle() }}>
          <MeetingHealth />
        </div>
      </ParallaxLayer>

      <ParallaxLayer offset={9} speed={0}>
        <motion.div
          className="absolute w-screen h-screen flex items-center"
          style={{ pointerEvents: isLargeScreen ? 'auto' : 'none', ...getStatCardStyle() }}>
          <MeetingSize />
        </motion.div>
      </ParallaxLayer>
    </ParallaxLayer>
  );
};

export default Stats;

const statAnim = {
  show: { opacity: 1, y: 0, transition: { type: 'tween', easings: 'easeInOut', duration: 0.3, delay: 0.5 } },
  hidden: { opacity: 0, y: -50, transition: { type: 'tween', easings: 'easeInOut', duration: 0.05 } },
  hiddenBottom: { opacity: 0, y: 50, transition: { type: 'tween', easings: 'easeInOut', duration: 0.05 } },
};

const getStatCardStyle = () => {
  return {
    paddingLeft: isSmallScreen ? '6%' : isMobile ? '2%' : 140,
    // marginTop: matchScreenSize('sm') ? 20 : '30%',
    // left: matchScreenSize('sm') ? '7%' : 110,
    // top: matchScreenSize('sm') ? 20 : null,
    width: isSmallScreen ? '90%' : isMobile ? '96%' : '76%',
  };
};
