import { Checkbox, Col, Modal, Row } from 'antd';
import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './CalendarPermission.module.css';
import BtnPrimary from 'components/Buttons/BtnPrimary';

function CalendarPermission({ permissionModalVisible, requestPermission }) {
    return (
        <Modal
            centered
            visible={permissionModalVisible}
            footer={null}
            closable={false}
            className="custom-modal text-white font-primary"
            bodyStyle={{ background: 'linear-gradient(to left, #0C141AB0, #2F3E49' }}
        >
            <div className={styles.card}>
                <Row>
                    <div className={styles.iconBox}>
                        <ExclamationCircleOutlined />
                    </div>
                    <div className={styles.flex}>
                        <span className={styles.title}>Grant calendar permission</span>
                        <span className={styles.sub}>
                            In order to use Year In Meetings properly, you need to give Year In Meetings read-only access to
                            your Google Calendar.
                        </span>
                    </div>
                </Row>
            </div>
            <BtnPrimary text="Allow Google Calendar access" onClick={requestPermission} />
        </Modal>
    );
}

export default CalendarPermission;