import { Popover } from 'antd';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share2.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { SocialButtonOutlined } from 'components/SocialShare';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { isSmallScreen } from 'utils/misc';

export default function Statistics({ title, subtitle, children, chart, info, share }) {
  return (
    <div
      className="grid grid-cols-10 gap-5 w-full"
      style={{ background: 'rgba(0, 0, 0, 0.70)', padding: 24, backdropFilter: 'blur(9px)' }}>
      <div className="col-span-10 lg:col-span-5 relative">
        <div>
          <h1
            className="font-display"
            style={{
              color: '#72FEB9',
              fontSize: isSmallScreen ? 56 : 100,
              lineHeight: isSmallScreen ? '56px' : '92px',
              fontWeight: 400,
            }}>
            {title}
          </h1>
        </div>
        <div
          className="text-white font-exo2 "
          style={{ fontSize: isSmallScreen ? 20 : 24, fontWeight: 600, lineHeight: isSmallScreen ? '24px' : '28px' }}>
          {subtitle}
        </div>
        <div>{children}</div>
        <div style={{ height: 45 }} />

        <div className="flex items-center justify-between absolute bottom-0 left-0 w-full">
          {share ? (
            <Popover
              overlayInnerStyle={{ background: '#2B3948', position: 'relative', top: 8, padding: 0 }}
              placement="topLeft"
              overlayClassName="btn-share"
              content={
                <div className="flex flex-col">
                  <FacebookShareButton quote={share.caption} url={share.url}>
                    <SocialButtonOutlined
                      type="outline"
                      icon={<FacebookIcon className="btn-social" fill="#72FEB9" />}
                      className="social-share-btn"
                      text={
                        <span
                          className="relative top-0.5"
                          style={{ fontWeight: 500, fontSize: 14, lineHeight: '16px' }}>
                          Facebook
                        </span>
                      }
                      color="#1877F2"
                    />
                  </FacebookShareButton>
                  <LinkedinShareButton title={share.caption} url={share.url}>
                    <SocialButtonOutlined
                      type="outline"
                      icon={<LinkedinIcon className="btn-social" fill="#72FEB9" />}
                      className="social-share-btn"
                      text={
                        <span
                          className="relative top-0.5"
                          style={{ fontWeight: 500, fontSize: 14, lineHeight: '16px' }}>
                          LinkedIn
                        </span>
                      }
                      color="#0073B1"
                    />
                  </LinkedinShareButton>
                  <TwitterShareButton title={share.caption} url={share.url}>
                    <SocialButtonOutlined
                      type="outline"
                      icon={<TwitterIcon className="btn-social" fill="#72FEB9" />}
                      className="social-share-btn"
                      text={<span style={{ fontWeight: 500, fontSize: 14, lineHeight: '16px' }}>Twitter</span>}
                      color="#1DA1F2"
                    />
                  </TwitterShareButton>
                </div>
              }>
              <div
                className="bg-white cursor-pointer flex items-center"
                style={{
                  background: '#72FEB9',
                  color: '#000000',
                  padding: isSmallScreen ? '5px 14px 5px 8px' : '6px 14px 5px 10px',
                }}>
                <ShareIcon className="mr-3" />
                <span className="font-semibold text-lg" style={{ color: '#000000', lineHeight: '19px' }}>
                  Share this stat
                </span>
              </div>
            </Popover>
          ) : null}
          <div>
            {info ? (
              <Popover
                overlayStyle={{ width: 450 }}
                placement="topRight"
                content={<span className="text-right">{info}</span>}>
                <div
                  className="h-8 w-12 flex items-center justify-center rounded-full cursor-pointer"
                  style={{ background: '#E1E1E1' }}>
                  <InfoIcon />
                </div>
              </Popover>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-span-10 lg:col-span-5">{chart}</div>
    </div>
  );
}
