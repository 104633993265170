import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import React, { useLayoutEffect, useMemo } from 'react';
import { isSmallScreen } from 'utils/misc';

am4core.options.queue = true;

const LineSmooth = ({ data }) => {
  const id = useMemo(() => 'chart-' + Math.random(), []);

  useLayoutEffect(() => {
    var chart = am4core.create(id, am4charts.XYChart);
    chart.data = data;
    chart.padding(0);

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'key';
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.grid.template.location = 0.5;

    // Create value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.fill = '#89FFC5';
    valueAxis.renderer.baseGrid.disabled = true;

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'key';
    series.strokeWidth = 3;
    series.tensionX = 0.77;
    series.stroke = am4core.color('white');
    series.fill = series.stroke;
    series.showOnInit = false;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <div id={id} style={{ width: '100%', height: isSmallScreen ? 25 : 50, transform: 'translate(-20px, 0px)' }}></div>
  );
};

export default React.memo(LineSmooth);
