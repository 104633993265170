import React from 'react';
import { Button } from 'antd';
import css from './Btn.module.css';

const BtnPrimary = ({ loading, onClick, text, disabled, icon, thin, bold, reverse, ...props }) => {
  let clasS = thin ? css.thin : bold ? css.bold : reverse ? css.reverse : css.btn;
  clasS += disabled ? ' ' + css.disabled : '';

  return (
    <Button
      {...props}
      disabled={disabled}
      icon={icon}
      className={clasS}
      loading={loading}
      onClick={onClick}>
      {text}
    </Button>
  );
};

export default BtnPrimary;
