import { animate } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

export default function Counter({ from = 0, to = 0, isVisible, duration = 1, style, autoWidth = false }) {
  const [countFrom, setFrom] = useState(from);
  const precision = to % 1 ? to.toString().split('.')[1].length : 0;
  const nodeRef = useRef();

  const runCounter = () => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration,
      ease: 'easeInOut',
      onUpdate(value) {
        node.textContent = value.toFixed(precision);
      },
    });
  };

  useEffect(() => {
    const node = nodeRef.current;
    setFrom(to);
    const controls = animate(countFrom, to, {
      duration,
      ease: 'easeInOut',
      // onComplete: () => setFrom(to),
      onUpdate(value) {
        node.textContent = value.toFixed(precision);
      },
    });

    return () => controls.stop();
  }, [from, to]);

  useEffect(() => {
    if (isVisible) runCounter();
  }, [isVisible]);

  return (
    <div
      ref={nodeRef}
      className="inline-block text-center"
      style={{ width: !autoWidth ? to?.toFixed(precision).toString().length * 20 : 'auto', ...style }}
    />
  );
}
