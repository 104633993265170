import { dropUser } from 'actions';
import React from 'react';
import { logout } from 'utils/auth';

export default function DevButtons({ showHUD, setShowHUD, setPhase }) {
  return (
    <div className="absolute top-0 right-0 cursor-pointer">
      <div title="logout" className="bg-red-500 p-2 text-white" onClick={logout}>
        logout
      </div>
      {/* <div
        title="Toggle HUD"
        className="bg-green-500 p-2 text-white"
        onClick={() => {
          setShowHUD(!showHUD);
        }}>
        Toggle HUD
      </div> */}
      <div
        title="Delete"
        className="bg-red-500 p-2 text-white"
        onClick={() => {
          dropUser();
        }}>
        Delete
      </div>
      {/* <div
        title="switch"
        className="bg-blue-500 p-2 text-white"
        onClick={() => {
          setPhase('loadingComplete');
        }}>
        Switch
      </div> */}
    </div>
  );
}
