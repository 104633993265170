import { Alert, Checkbox, Col, message, Modal, Row } from 'antd';
import React from 'react';
import PrimaryModal from './PrimaryModal';
import CloseIcon from 'assets/icons/close.svg';

function CalendarImport({
  importModalVisible,
  setImportModalVisible,
  calendars,
  setSelectedCalendars,
  selectedList,
  importCalendarHandler,
}) {
  const importBtnHandler = async () => {
    if (!selectedList || selectedList.length < 1) {
      message.info('Please select a calendar');
      return;
    }
    importCalendarHandler();
  };

  return (
    <PrimaryModal title="Import Calendars" visible={importModalVisible} setVisible={setImportModalVisible} width={923}>
      <div className="font-exo2" style={{ lineHeight: '33px', fontWeight: 400, fontSize: 28, marginTop: 24 }}>
        Import only the calendars you use{' '}
      </div>
      <div style={{ marginTop: 16, maxHeight: 'calc(100vh - 520px)', overflowY: 'auto' }} className="import-checkbox">
        <Checkbox.Group onChange={setSelectedCalendars} value={selectedList}>
          {calendars
            ? calendars.map((item) => (
                <div key={item.calendarId} style={{ marginBottom: 16 }}>
                  <Checkbox
                    className="text-md flex items-center"
                    style={{ color: 'white' }}
                    value={item.calendarId}
                    disabled={item?.accessRole === 'freeBusyReader' ? true : false}>
                    <div
                      className="font-secondary"
                      style={{
                        fontWeight: 500,
                        fontSize: 16,
                        lineHeight: '24px',
                        marginLeft: 4,
                        position: 'relative',
                        top: 2,
                      }}>
                      {item.title} {item?.accessRole === 'freeBusyReader' ? '(free/busy access)' : null}
                    </div>
                  </Checkbox>
                </div>
              ))
            : null}
        </Checkbox.Group>
      </div>
      <div className="mt-2">
        <button
          className="font-exo2"
          style={{
            background: '#72FEB9',
            color: '#000000',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '32px',
            padding: '4px 16px',
            marginTop: 12,
          }}
          onClick={() => importBtnHandler()}>
          Import Now
        </button>
      </div>
    </PrimaryModal>
  );
}

export default CalendarImport;

const style = {
  modal: {
    background: 'rgba(38, 95, 107, 0.8)',
    backdropFilter: 'blur(10px)',
    padding: '32px 48px',
  },
  title: {
    fontSize: 32,
    lineHeight: '38px',
    fontWeight: 600,
    color: 'white',
  },
};
