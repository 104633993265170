import React, { useEffect, useState } from 'react';
import Footer from './home/components/Footer';
import LoadingIcon from 'assets/icons/loading.svg';
import LoadingRocket from 'assets/images/rocket-v.gif';
import MeetingCount from 'components/Statistics/MeetingCount';
import MeetingHours from 'components/Statistics/MeetingHours';
import MeetingPeople from 'components/Statistics/MeetingPeople';
import MeetingStack from 'components/Statistics/MeetingStack';
import MeetingFatigue from 'components/Statistics/MeetingFatigue';
import MeetingUnique from 'components/Statistics/MeetingUnique';
import MeetingHealth from 'components/Statistics/MeetingHealth';
import DeepWork from 'components/Statistics/DeepWork';
import { fetchAnalyticsData } from 'store/misc';
import { useDispatch } from 'react-redux';
import DevButtons from 'components/DevButtons';

const Playground = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnalyticsData());
  }, []);

  return (
    <div
      className="lg:min-h-screen pt-10"
      style={{ background: 'url(https://cdn.wallpapersafari.com/19/65/jTIJ4w.jpg)', backgroundSize: 'cover' }}>
      {/* <MeetingCount />
      <MeetingHours />
      <MeetingPeople />
      <MeetingUnique />
      <MeetingStack />
      <MeetingFatigue />
      <MeetingHealth />
      <DeepWork /> */}
      <Footer />
      <DevButtons />
    </div>
  );
};

export default Playground;
