import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import misc from './misc';

const rootReducer = combineReducers({ misc });

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
