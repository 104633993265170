import Bar from 'components/Charts/Bar';
import Counter from 'components/Counter';
import React from 'react';
import { useSelector } from 'react-redux';
import { isSmallScreen, number } from 'utils/misc';
import Statistics from '.';
import { getTopPeopleData } from './actions';

export default function MeetingPeople({ isVisible = true }) {
  const { data } = useSelector((state) => state.misc);

  const sortedTopPeople = data?.topPeople.sort((a, b) => b.hourSpent - a.hourSpent) ?? [];
  const chartData = getTopPeopleData(sortedTopPeople?.slice(0, 8));

  const shareConfig = {
    caption: `I spent ${number(sortedTopPeople[0]?.hourSpent)} hours in meetings with ${
      sortedTopPeople[0]?.name || sortedTopPeople[0]?.email
    } in 2021 🔥. How about you? Get your report\n\n`,
    url: 'https://2021.tackle.to',
  };

  return (
    <Statistics
      title={<Counter isVisible={isVisible} to={number(sortedTopPeople[0]?.hourSpent)} />}
      subtitle={`Hours in meeting with ${sortedTopPeople[0]?.name || sortedTopPeople[0]?.email}`}
      share={shareConfig}
      chart={
        <div className="relative" style={{ background: '#263D6B', paddingTop: isSmallScreen ? 10 : 40 }}>
          <Bar data={chartData} />
        </div>
      }>
      <div style={{ marginTop: 24 }}>
        <div className="font-exo2" style={{ fontWeight: 400, fontSize: 18, lineHeight: '24px', color: '#E1E1E1' }}>
          Next is {sortedTopPeople[1]?.name || sortedTopPeople[1]?.email} ({number(sortedTopPeople[1]?.hourSpent)}{' '}
          hours)
          {sortedTopPeople[2] ? (
            <span>
              &nbsp; and {sortedTopPeople[2]?.name || sortedTopPeople[2]?.email} (
              {number(sortedTopPeople[2]?.hourSpent)} hours)
            </span>
          ) : null}
        </div>
      </div>
    </Statistics>
  );
}
