import React from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/scroll_arrow_icon.svg';

export default function ScrollBottom({ isVisible, next }) {
  return (
    <div
      className={`absolute -bottom-1 left-1/2 transform -translate-x-1/2 -rotate-90 text-right cursor-default hover:-translate-y-3 transition ${
        !isVisible ? 'hidden' : ''
      }`}
      style={{ zIndex: 999 }}
      onClick={() => next?.()}>
      <div className="relative" style={{ width: 122, height: 3, background: '#333333' }}>
        <div className="h-full bg-white absolute scroller" />
      </div>
      <div className="" style={{ transform: 'translateX(60px)' }}>
        <div
          className="font-primary text-white mt-1 flex items-center"
          style={{ fontSize: 18, lineHeight: '18px', fontWeight: 700, position: 'relative', left: 21 }}>
          <div className="scroll-arrow absolute inline-block">
            <ArrowDownIcon style={{ marginRight: 10 }} />
          </div>
          Scroll{' '}
        </div>
      </div>
    </div>
  );
}
