import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import React, { useLayoutEffect, useMemo } from 'react';
import { generateAvatarFromLetter, isMobile, isSmallScreen } from 'utils/misc';

am4core.options.queue = true;

const Bar = ({ data }) => {
  const id = useMemo(() => 'chart-' + Math.random(), []);

  useLayoutEffect(() => {
    var chart = am4core.create(id, am4charts.XYChart);
    chart.data = data;
    // chart.paddingTop = 40;
    chart.paddingRight = 70;
    // chart.paddingLeft = 20;
    chart.svgContainer.autoResize = true;
    chart.paddingLeft = 75;

    const maxItem = data.sort((a, b) => b.hourSpent - a.hourSpent)[0];

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = 'name';
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.labels.template.fill = 'white';

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.fill = 'white';

    categoryAxis.renderer.line.stroke = valueAxis.renderer.line.stroke = '#C8C8C8';

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = 'name';
    series.dataFields.valueX = 'hourSpent';
    series.columns.template.strokeOpacity = 0;
    series.columns.template.tooltipText = '{categoryY}: [bold]{valueX}[/]';
    series.columns.template.tooltipPosition = 'pointer';

    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = '#fff';
    series.tooltip.background.fillOpacity = 1;
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = '#263451';
    series.showOnInit = false;

    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.horizontalCenter = 'bottom';
    labelBullet.label.dx = 10;
    labelBullet.label.fontSize = isSmallScreen ? 12 : 16;
    labelBullet.label.fill = 'white';
    labelBullet.label.adapter.add('text', (a, target) => {
      if (target._dataItem.categories.categoryY === maxItem?.name) {
        return "[bold]{values.valueX.workingValue.formatNumber('#.0as')}";
      }
      return "{values.valueX.workingValue.formatNumber('#.0as')}";
    });

    let div = new am4core.Container();
    div.dx = -15;
    let image = div.createChild(am4core.Image);
    image.horizontalCenter = 'middle';
    image.width = 30;
    image.height = 30;
    image.strokeLinejoin = 'round';
    image.verticalCenter = 'middle';
    image.adapter.add('href', (href, target) => {
      let category = target.dataItem.category;
      if (category) {
        const textColor = category === maxItem?.name ? '000000b3' : 'fff';
        const bgColor = category === maxItem?.name ? '#72FEB9' : '#000000b3';
        return generateAvatarFromLetter(category[0], bgColor, textColor);
      }
      return href;
    });
    image.showSystemTooltip = true;
    image.dx = -15;
    let mask = new am4core.Circle();
    mask.horizontalCenter = 'middle';
    mask.verticalCenter = 'middle';
    mask.align = 'center';
    mask.valign = 'top';
    mask.parent = div;
    mask.radius = 15;
    image.parent = div;
    image.adapter.add('mask', function (mask, target) {
      return target.parent.children.getIndex(1);
    });

    categoryAxis.dataItems.template.bullet = div;
    categoryAxis.dataItems.template.bullet.tooltipText = '{category}';
    categoryAxis.dataItems.template.bullet.tooltipX = -15;
    categoryAxis.dataItems.template.bullet.tooltipY = -15;

    series.columns.template.adapter.add('fill', function (fill, target) {
      if (target.dataItem.valueX === maxItem?.hourSpent) {
        return '#72FEB9';
      }
      return 'rgba(0, 0, 0, 0.7)';
    });

    categoryAxis.sortBySeries = series;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: '100%', height: isMobile ? 200 : 400, marginTop: -20 }}></div>;
};

export default React.memo(Bar);
