import MeetingFatigueImg from 'assets/images/meeting_fatigue.png';
import Counter from 'components/Counter';
import React from 'react';
import { useSelector } from 'react-redux';
import { number } from 'utils/misc';
import Statistics from '.';
import { getMeetingFatigueState } from './actions';

export default function MeetingFatigue({ isVisible = true }) {
  const { data } = useSelector((state) => state.misc);
  const fatigueScore = Math.max((100 - data?.meetingFatigueScore) / 10, 0);
  const { messageState, fillColor } = getMeetingFatigueState(data);

  return (
    <Statistics
      title={
        <div className="flex items-center">
          <Counter
            isVisible={isVisible}
            to={number(fatigueScore)}
            style={{
              color: fillColor,
            }}
          />
        </div>
      }
      subtitle="Meeting Fatigue Score ™"
      info={
        <span>
          Meeting Fatigue Score ™ is calculated from different meeting variables including frequency, length and size of
          meetings. The score ranges from 0 to 10. The higher the number, the better!
        </span>
      }
      chart={<img src={MeetingFatigueImg} />}>
      <div style={{ marginTop: 24 }}>
        <div className="font-exo2" style={{ fontWeight: 400, fontSize: 18, lineHeight: '24px', color: '#E1E1E1' }}>
          Meeting Fatigue (often called Zoom Fatigue) is the exhaustion, anxiety, and burnout associated with the
          overuse of online meetings. If you're a manager, then chances are you already suffered from it!
        </div>
      </div>

      <p className="text-white font-exo2 flex items-center" style={{ marginTop: 72 }}>
        {messageState === 'positive'
          ? '👍 Frequently suffer from meeting fatigue'
          : messageState === 'warning'
          ? '😐 Occasionally suffer from meeting fatigue'
          : '👎 Probably not suffering from meeting fatigue'}
      </p>
    </Statistics>
  );
}
