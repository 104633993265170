import PrimaryModal from 'components/PrimaryModal';
import React from 'react';

const PrivacyModal = ({ privacyModalVisible, setPrivacyModalVisible }) => {
  return (
    <PrimaryModal title="Data Privacy" width={944} visible={privacyModalVisible} setVisible={setPrivacyModalVisible}>
      <p className="font-medium mt-3" style={{ fontSize: 20, lineHeight: '32px' }}>
        We 👩‍✈️️ are not going to bore you before you take your flight with a long inexplicable privacy policy. So, here's
        what we do to protect 🔒 your data in a few simple sentences:
      </p>
      <p className="font-medium mt-5" style={{ fontSize: 20, lineHeight: '32px' }}>
        👊 We process all your data in memory and throw away everything except the aggregated chart data that you see in
        the app. <br />
        🗑 We delete your access tokens right after generating the data.
        <br />
        ⛔️ We do not share your data with any third parties. <br />
        ✉️ We may however email you occasionally about product updates that you can always opt-out from.
      </p>
    </PrimaryModal>
  );
};

export default PrivacyModal;
