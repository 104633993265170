import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import starsTop from 'assets/images/bg-stars-top.png';
import starsBottom from 'assets/images/bg-stars-bottom.png';
import { delay } from 'utils/delay';

const Stars = ({ phase }) => {
  const starControls = useAnimation();

  useEffect(() => {
    if (phase === 'loading') {
      delay(4000).then(() => starControls.start('move'));
    } else if (phase === 'scroll') {
      starControls.start('hide');
    }
  }, [phase]);

  return (
    <>
      {/* <motion.div
        variants={bottom}
        initial="initial"
        animate={starControls}
        className="absolute w-screen pointer-events-none"
        style={{
          height: '200vh',
          backgroundImage: `url(${starsBottom})`,
          backgroundSize: 'contain',
          pointerEvents: 'none',
        }}
      /> */}

      {/* Stars Top */}
      <motion.div
        className="absolute w-screen pointer-events-none"
        style={{
          height: '100vh',
          backgroundImage: `url(${starsTop})`,
          backgroundSize: 'contain',
          pointerEvents: 'none',
        }}
        initial="initial"
        variants={top}
        animate={starControls}
      />

      <motion.div
        className="absolute w-screen pointer-events-none"
        style={{
          height: '100vh',
          backgroundImage: `url(${starsTop})`,
          backgroundSize: 'contain',
          pointerEvents: 'none',
        }}
        initial="initial"
        variants={top2}
        animate={starControls}
      />
    </>
  );
};

export default React.memo(Stars);

const top = {
  initial: { y: '-100vh', opacity: 1 },
  move: {
    y: '120vh',
    transition: { type: 'keyframes', duration: 6, repeat: Infinity, ease: 'linear' },
  },
  hide: { opacity: 0, transition: { duration: 0.1 } },
};

const top2 = {
  initial: { y: '-100vh', opacity: 1 },
  move: {
    y: '120vh',
    transition: { type: 'keyframes', delay: 3, duration: 6, repeat: Infinity, ease: 'linear' },
  },

  hide: { opacity: 0, transition: { duration: 0.1 } },
};

const bottom = {
  initial: { y: '-200vh', opacity: 1 },
  move: {
    y: '120vh',
    transition: { type: 'keyframes', delay: 3, duration: 25, repeat: Infinity, ease: 'linear' },
  },
  speedup: {
    y: '125vh',
    transition: { type: 'keyframes', duration: 9, repeat: Infinity, ease: 'linear' },
  },
  hide: { opacity: 0, transition: { duration: 1 } },
};
