import BtnPrimary from 'components/Buttons/BtnPrimary';
import PrimaryModal from 'components/PrimaryModal';
import React from 'react';

const NewAppModal = ({ newAppModalVisible, setNewAppModalVisible }) => {
  const gotoMainApp = () => {
    setNewAppModalVisible(false)
    window.location.href = 'https://www.timetackle.com/'
  }
  return (
    <PrimaryModal title="Announcement" width={944} visible={newAppModalVisible} setVisible={setNewAppModalVisible}>
      <p className="font-medium mt-3" style={{ fontSize: 20, lineHeight: '32px' }}>
        It's already Q2 2022, so we decided to turn this year in review app down. But you can always try our main product <a href='https://www.timetackle.com/' style={{ color: '#72feb9', textDecoration: 'underline' }}>Tackle</a> and get your stats for free.
      </p>
      <br />
      <BtnPrimary reverse={true} text={'I am game!'} onClick={gotoMainApp} />
    </PrimaryModal>
  );
};

export default NewAppModal;
