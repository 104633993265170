import { months } from 'utils/constants';

export const getFooterData = (data) => {
  if (data) {
    const monthlyMeetingTrend = Object.keys(data?.monthlyMeetings)
      .sort((a, b) => months.indexOf(a) - months.indexOf(b))
      .map((key) => ({
        key,
        value: data?.monthlyMeetings[key],
      }));

    const monthlyHourSpentTrend = Object.keys(data?.monthlyHourSpent)
      .sort((a, b) => months.indexOf(a) - months.indexOf(b))
      .map((key) => ({
        key,
        value: data?.monthlyHourSpent[key],
      }));

    const monthlyPeopleMetTrend = Object.keys(data?.monthlyPeopleMet)
      .sort((a, b) => months.indexOf(a) - months.indexOf(b))
      .map((key) => ({
        key,
        value: data?.monthlyPeopleMet[key],
      }));

    const monthlyMeetingHealthScoreTrend = Object.keys(data?.monthlyMeetingHealthScore)
      .sort((a, b) => months.indexOf(a) - months.indexOf(b))
      .map((key) => ({
        key,
        value: data?.monthlyMeetingHealthScore[key],
      }));

    return { monthlyMeetingTrend, monthlyHourSpentTrend, monthlyPeopleMetTrend, monthlyMeetingHealthScoreTrend };
  }

  return {};
};
