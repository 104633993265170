import { motion } from 'framer-motion';
import React from 'react';
import planet1 from 'assets/images/space/planet-1.png';
import planet2 from 'assets/images/space/planet-2.png';
import planet3 from 'assets/images/space/planet-3.png';
import nebula from 'assets/images/space/nebula.png';
import cloud3 from 'assets/images/space/cloud-3.png';
import cloud4 from 'assets/images/space/cloud-4.png';
import satellite from 'assets/images/space/satellite-station.png';
import astro from 'assets/images/space/astronaut.png';
import karman from 'assets/images/space/karman-line.png';

const SpaceStuffs = ({ combinedControls }) => {
  return (
    <>
      {/* Cloud 3 */}
      <motion.div
        initial={{ y: 0 }}
        variants={cloud3Anim}
        animate={combinedControls}
        className="absolute  pointer-events-none"
        style={{ height: 135, width: 705, top: -200, right: 0 }}>
        <img src={cloud3} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* Cloud 4 */}
      <motion.div
        initial={{ y: 0 }}
        variants={cloud4Anim}
        animate={combinedControls}
        className="absolute  pointer-events-none"
        style={{ height: 115, width: 600, top: -200, left: 20 }}>
        <img src={cloud4} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* Planet 1 */}
      <motion.div
        initial="initial"
        variants={planet1Anim}
        animate={combinedControls}
        className="absolute  pointer-events-none"
        style={{ height: 200, width: 200, top: -200, left: 260 }}>
        <img src={planet1} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* Planet 2 */}
      <motion.div
        initial="initial"
        variants={planet2Anim}
        animate={combinedControls}
        className="absolute pointer-events-none"
        style={{ height: 500, top: -600, right: -140 }}>
        <img src={planet2} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* Planet 3 */}
      <motion.div
        initial="initial"
        variants={planet3Anim}
        animate={combinedControls}
        className="absolute pointer-events-none"
        style={{ height: 144, width: 240, top: -500, left: 220 }}>
        <img src={planet3} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* Nebula 1 */}
      <motion.div
        initial="initial"
        variants={nebula1Anim}
        animate={combinedControls}
        className="absolute pointer-events-none"
        style={{ height: 140, top: -200, right: 500 }}>
        <img src={nebula} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* Nebula 2 */}
      <motion.div
        initial="initial"
        variants={nebula2Anim}
        animate={combinedControls}
        className="absolute pointer-events-none"
        style={{ height: 140, top: -200, left: 200 }}>
        <img src={nebula} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* Satellite */}
      <motion.div
        initial="initial"
        variants={satelliteAnim}
        animate={combinedControls}
        className="absolute pointer-events-none"
        style={{ height: 400, top: -600, right: 0 }}>
        <img src={satellite} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* Astro */}
      <motion.div
        initial="initial"
        variants={satelliteAnim}
        animate={combinedControls}
        className="absolute pointer-events-none"
        style={{ height: 200, top: -600, right: 50 }}>
        <img src={astro} style={{ maxHeight: '100%' }} />
      </motion.div>

      {/* Karman Line */}
      <motion.div
        initial="initial"
        variants={karmanAnim}
        animate={combinedControls}
        className="absolute pointer-events-none w-screen"
        style={{ width: window.innerWidth + 60, top: -window.innerWidth, left: -20, mixBlendMode: 'overlay' }}>
        <img src={karman} style={{ width: '100%', maxHeight: '100%' }} />
      </motion.div>
    </>
  );
};

export default SpaceStuffs;

const cloud3Anim = {
  move: { y: window.innerHeight + 1500, transition: { duration: 4.5, delay: 2 } },
};

const cloud4Anim = {
  move: { y: window.innerHeight + 1500, transition: { duration: 4, delay: 3.5 } },
};

const planet1Anim = {
  initial: { y: 0 },
  move: { y: window.innerHeight + 1000, transition: { duration: 5, delay: 5 } },
};

const planet2Anim = {
  initial: { y: 0 },
  move: { y: window.innerHeight + 1500, transition: { duration: 5, delay: 8 } },
};

const planet3Anim = {
  initial: { y: 0 },
  move: { y: window.innerHeight + 1000, transition: { duration: 4, delay: 10 } },
};

const nebula1Anim = {
  initial: { y: 0 },
  move: { y: window.innerHeight + 1500, transition: { duration: 4.3, delay: 11 } },
};

const nebula2Anim = {
  initial: { y: 0 },
  move: { y: window.innerHeight + 1500, transition: { duration: 7, delay: 15 } },
};

const satelliteAnim = {
  initial: { y: 0 },
  move: { y: window.innerHeight + 1500, transition: { duration: 5, delay: 4 } },
};

const karmanAnim = {
  initial: { y: 0 },
  move: {
    y: window.innerHeight + 5500,
    transition: { duration: 4, delay: 12 },
  },
};
