import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import React, { useLayoutEffect, useMemo } from 'react';
import { isMobile } from 'utils/misc';

am4core.options.queue = true;

const Area = ({ data }) => {
  const id = useMemo(() => 'chart-' + Math.random(), []);

  useLayoutEffect(() => {
    var chart = am4core.create(id, am4charts.XYChart);
    chart.data = data;
    chart.padding = 0;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = -2;

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.labels.template.fill = 'white';

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.fill = 'white';

    function createSeries(field, name, fillColor) {
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'category';
      series.strokeWidth = 3;
      series.stroke = fillColor;
      series.yAxis = valueAxis;
      series.name = name;
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.tensionX = 0.8;
      series.showOnInit = false;
      series.fillOpacity = 0.59;
      series.fill = fillColor;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = '#fff';
      series.tooltip.background.fillOpacity = 1;
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = '#263451';

      let fillModifier = new am4core.LinearGradientModifier();
      fillModifier.opacities = [0.6, 0];
      fillModifier.offsets = [0, 1];
      fillModifier.gradient.rotation = 90;
      series.segments.template.fillModifier = fillModifier;

      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.fill = am4core.color('#fff');
      bullet.circle.strokeWidth = 2;
      bullet.circle.stroke = fillColor;
      bullet.circle.strokeOpacity = 0.59;
    }

    createSeries('value1', 'Recurring', '#FFB8A1');
    createSeries('value2', 'Non-recurring', '#72FEB9');

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: '100%', height: isMobile ? 200 : 400 }}></div>;
};

export default React.memo(Area);
