import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import ground from 'assets/images/ground.png';
import yimLogo from 'assets/images/logo/logo_2021.png';
import tackle from 'assets/images/logo/logo_tackle.png';
import mountainBack from 'assets/images/mountain_back.png';
import { ReactComponent as ProductHuntLogo } from 'assets/icons/producthunt-logo.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as RocketIcon } from 'assets/icons/rocket.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { useNavigate } from 'react-router-dom';
import shadow from 'assets/images/rocket/shadow.png';
import LineSmooth from 'components/Charts/LineSmooth';
import ShareModal from 'components/ShareModal';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { isLongScreen, isMobile, isSmallScreen, meetingHealthGrade, number } from 'utils/misc';
import { getFooterData } from './actions';
import ShareButton from 'components/Buttons/ShareButton';
import { SET_PRIVACY_MODAL_VISIBILITY, SET_SOCIAL_MODAL_VISIBILITY } from 'store/misc';
import PrivacyModal from 'pages/auth/components/PrivacyModal';

const WIDTH = window.innerWidth;
const isLargeScreen = WIDTH > 900;

export default function Footer({ isVisitor }) {
  const { data, privacyModalVisible, socialModalVisible } = useSelector((state) => state.misc);
  const [footerItems, setFooterItems] = useState([]);
  const { inView, ref } = useInView();
  const shadowControl = useAnimation();
  const footerData = getFooterData(data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productHuntURL = 'https://www.producthunt.com/posts/2021-your-year-in-meetings';

  useEffect(() => {
    const tempFooterItems = [
      {
        title: number(data?.meetings),
        subtitle: 'meetings',
        caption: `${Math.ceil(number(data?.workDayMeetingAvg))} meetings on average on weekdays`,
        chart: <LineSmooth data={footerData.monthlyMeetingTrend} />,
      },
      {
        title: number(data?.meetingHours),
        subtitle: 'hours',
        caption: `${number(data?.workHourSpentAvg)}% of workday is spent in meetings`,
        chart: <LineSmooth data={footerData.monthlyHourSpentTrend} />,
      },
      {
        title: number(data?.uniquePeople.total),
        subtitle: 'people',
        caption: `Met ${number(data?.uniquePeople.externalDomain)} people from outside of my company`,
        chart: <LineSmooth data={footerData.monthlyPeopleMetTrend} />,
      },
      {
        title: (
          <span style={{ color: meetingHealthGrade(number(data?.meetingHealthScore))?.color }}>
            {meetingHealthGrade(number(data?.meetingHealthScore))?.grade}
          </span>
        ),
        subtitle: 'meeting health',
        caption: `Meeting Fatigue Score is ${number(
          Math.max((100 - data?.meetingFatigueScore) / 10, 0)
        )}<br />Deep Work Score is ${number((data?.deepWorkScore * 10) / 100)}`,
        chart: <LineSmooth data={footerData?.monthlyMeetingHealthScoreTrend} />,
      },
    ];

    setFooterItems(tempFooterItems);
  }, [data]);

  useEffect(() => {
    if (inView) {
      shadowControl.start({ scale: 1, transition: { duration: 10 } });
    } else shadowControl.start({ scale: 0 });
  }, [inView]);

  const setShareVisible = (value) => {
    dispatch({
      type: SET_SOCIAL_MODAL_VISIBILITY,
      payload: value,
    });
  };

  return (
    <div>
      <div className="flex flex-col justify-center items-center w-screen h-screen">
        <div
          className="absolute w-screen bottom-0 pointer-events-none"
          style={{
            bottom: isLargeScreen ? 117 : 0,
            height: isLargeScreen ? 237 : 140,
            backgroundImage: `url(${mountainBack})`,
            backgroundSize: 'cover',
            pointerEvents: 'none',
            zIndex: -20,
          }}></div>

        {/* Ground */}
        <div
          className="absolute w-screen bottom-0 pointer-events-none"
          style={{ height: isLargeScreen ? 203 : 60, zIndex: -20 }}>
          <img src={ground} style={{ width: '100%', maxWidth: '100%', height: '100%' }} />
        </div>

        {/* Shadow */}
        <motion.div
          className="absolute pointer-events-none"
          ref={ref}
          initial={{ scale: 0 }}
          animate={shadowControl}
          style={{ width: 100, zIndex: -20, left: '84%', bottom: isMobile ? -200 : isSmallScreen ? 115 : 115 }}>
          <img src={shadow} style={{ width: '100%', maxWidth: '100%', height: '100%' }} />
        </motion.div>

        <div className="flex-1 overflow-x-auto overflow-y-auto flex justify-center items-center">
          <div
            style={{
              padding: isSmallScreen || isMobile ? '12px 24px' : '24px 48px 48px',
              background: 'linear-gradient(180deg, rgba(8, 40, 98, 0.6) 0%, rgba(8, 28, 64, 0.6) 100%)',
              backdropFilter: 'blur(10px)',
              width: isSmallScreen ? '85vw' : isMobile ? '96vw' : '65vw',
            }}>
            <div className="flex justify-between">
              <h2
                className="font-exo2 text-white"
                style={{
                  fontWeight: 600,
                  fontSize: isSmallScreen || isMobile ? 24 : 48,
                  lineHeight: isSmallScreen || isMobile ? '26px' : '57.6px',
                  // height: isSmallScreen ? 60 : 80,
                }}>
                2021 in Meetings
              </h2>
              <div className="flex items-center" style={{ justifyContent: isMobile ? 'flex-start' : 'center' }}>
                <img
                  src={data?.profileImage ?? 'https://s3.amazonaws.com/mypresonus/profile/1432960478_922038.jpg'}
                  alt="user"
                  className="rounded-full"
                  style={{ width: isSmallScreen ? 32 : 40, height: isSmallScreen ? 32 : 40 }}
                />
                <div style={{ marginLeft: 8 }}>
                  <h4 style={{ fontWeight: 600, fontSize: 18, lineHeight: '21px', color: 'white' }}>{data?.name}</h4>
                  <span style={{ fontWeight: 300, fontSize: 16, lineHeight: '16px', color: 'white' }}>
                    {data?.email}
                  </span>
                </div>
              </div>
            </div>
            {isMobile ? (
              <div
                className="grid grid-cols-1"
                style={{
                  columnGap: 24,
                  rowGap: 16,
                  marginTop: 16,
                }}>
                {footerItems?.map((item, i) => (
                  <div key={i} className="col-span-1">
                    <FooterStat {...item} />
                  </div>
                ))}
              </div>
            ) : (
              <div
                className="grid grid-cols-2"
                style={{
                  columnGap: isSmallScreen ? 24 : 76,
                  rowGap: isSmallScreen ? 16 : 24,
                  marginTop: isSmallScreen ? 16 : 32,
                }}>
                {footerItems?.map((item, i) => (
                  <div key={i} className="col-span-1">
                    <FooterStat {...item} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div
          className="flex items-center"
          style={{ margin: isSmallScreen ? '16px 0px' : '40px 0px', flexDirection: isMobile ? 'column' : 'row' }}>
          {productHuntURL ? (
            <ShareButton
              leading={<ProductHuntLogo />}
              extra={<StarIcon />}
              title="LEAVE A REVIEW ON"
              subtitle="Product Hunt"
              onClick={() => window.open(productHuntURL)}
            />
          ) : null}
          <ShareButton
            leading={isVisitor ? <PlayIcon /> : <ShareIcon />}
            extra={<RocketIcon />}
            title={isVisitor ? 'Get your 2021 report' : 'Share your 2021'}
            subtitle={isVisitor ? 'Start Your Journey' : 'Meeting Report'}
            style={{ marginLeft: isMobile ? 0 : 50, marginTop: isMobile ? 8 : 0 }}
            shadow
            shine
            onClick={isVisitor ? () => navigate('/') : () => setShareVisible(true)}
          />
        </div>

        <div className="flex justify-center items-center" style={{ height: isSmallScreen ? 24 : 48 }}>
          <a href="https://www.timetackle.com/" target="_blank" rel="noreferrer" className="flex">
            <div className="font-exo2 text-white" style={{ fontSize: 12, fontWeight: 400 }}>
              Powered by
            </div>
            <div>
              <img src={tackle} style={{ height: 14, marginLeft: 7 }} />
            </div>
          </a>
        </div>
      </div>

      {isVisitor && !isSmallScreen ? (
        <div className="absolute right-10 top-10">
          <img src={yimLogo} style={{ width: isLongScreen ? 120 : 80, height: isLongScreen ? 120 : 80 }} />
        </div>
      ) : null}

      <ShareModal {...{ shareVisible: socialModalVisible, setShareVisible }} />

      {isVisitor ? (
        <span
          className="absolute text-white cursor-pointer underline"
          style={{ fontWeight: 600, fontSize: 16, lineHeight: '22px', right: 30, bottom: 30 }}
          onClick={() =>
            dispatch({
              type: SET_PRIVACY_MODAL_VISIBILITY,
              payload: true,
            })
          }>
          Data Privacy
        </span>
      ) : null}
      <PrivacyModal
        privacyModalVisible={privacyModalVisible}
        setPrivacyModalVisible={(visible) => {
          dispatch({
            type: SET_PRIVACY_MODAL_VISIBILITY,
            payload: visible,
          });
        }}
      />
    </div>
  );
}

const FooterStat = ({ title, subtitle, caption, chart }) => {
  return (
    <div className="text-white">
      <div className="flex items-end">
        <h3
          className="font-display"
          style={{
            color: '#89FFC5',
            fontWeight: 400,
            fontSize: isSmallScreen || isMobile ? 40 : 56,
            lineHeight: isSmallScreen || isMobile ? '40px' : '60px',
          }}>
          {title}
        </h3>
        <div
          className="font-display"
          style={{
            color: '#89FFC5',
            fontSize: 24,
            fontWeight: 400,
            lineHeight: '30px',
            marginBottom: 7,
            marginLeft: 7,
          }}>
          {subtitle}
        </div>
      </div>
      {isMobile ? null : chart}
      <div
        className="font-exo2"
        style={{
          fontWeight: 500,
          fontSize: isSmallScreen || isMobile ? 16 : 24,
          lineHeight: isSmallScreen || isMobile ? '20px' : '28px',
          marginTop: isSmallScreen || isMobile ? 16 : 20,
        }}>
        {caption.split('<br />')[0]} <br /> {caption.split('<br />')[1]}
      </div>
    </div>
  );
};
