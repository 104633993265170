import { domainTypeMap, meetingSizeMap, messageColor, months, weekMap } from 'utils/constants';
import { number } from 'utils/misc';

export const getMonthlyHourSpentData = (data) => {
  return months.map((month) => ({
    month: month[0].toUpperCase() + month.slice(1, 3).toLowerCase(),
    value: data[month],
  }));
};

export const getTopPeopleData = (data) => {
  return data.map((item) => ({
    name: item.name || item.email,
    hourSpent: item.hourSpent,
  }));
};

export const getMeetingNumbersData = (data) => {
  return data
    .filter((item) => item.hour >= 9 && item.hour < 18)
    .sort((a, b) => {
      const compareWeekDay = weekMap[a.weekday] - weekMap[b.weekday];
      const compareHour = a.hour - b.hour;

      return compareWeekDay || compareHour;
    })
    .map((item) => ({
      hour: `${((item?.hour + 11) % 12) + 1}${item.hour >= 12 ? 'pm' : 'am'}`,
      weekday: item.weekday[0].toUpperCase() + item.weekday.slice(1, 3).toLowerCase(),
      value: item.meetings,
    }));
};

export const getMeetingUniqueData = (data) => {
  return (
    data &&
    Object.keys(data).map((domainType) => ({
      field: domainTypeMap[domainType],
      value: data[domainType],
    }))
  );
};

export const getWeekendMeetingCount = (data) => {
  let count = 0;

  if (data) {
    data.hourlyStatistics
      .filter((item) => item.weekday === 'SATURDAY' || item.weekday === 'SUNDAY')
      .forEach((item) => {
        count += item.meetings;
      });
  }

  return count;
};

export const getMonthlyAvailableData = (data) => {
  return months.map((month) => ({
    category: month[0].toUpperCase() + month.slice(1, 3).toLowerCase(),
    value1: data[month],
    value2: 100 - data[month],
  }));
};

export const getMeetingSizeData = (data) => {
  return {
    meetingSize:
      data &&
      Object.keys(data.meetingSize).map((key) => ({
        field: meetingSizeMap[key],
        value: number(data.meetingSize[key]),
      })),
    meetingLength:
      data &&
      Object.keys(data.meetingLength).map((key) => ({
        field: meetingSizeMap[key],
        value: number(data.meetingLength[key]),
      })),
  };
};

export const getRecurringMeetingData = (data) => {
  return data?.meetingType
    .sort((a, b) => months.indexOf(a.month) - months.indexOf(b.month))
    .map((item) => ({
      category: item.month[0] + item.month.slice(1, 3).toLowerCase(),
      value1: number(item.recurring),
      value2: number(item.adHoc),
    }));
};

export const getMeetingFatigueState = (data) => {
  const messageState =
    data?.meetingFatigueScore < 20
      ? 'positive'
      : data?.meetingFatigueScore >= 20 && data?.meetingFatigueScore <= 40
      ? 'warning'
      : 'negative';

  const fillColor =
    data?.meetingFatigueScore < 20
      ? '#72FEB9'
      : data?.meetingFatigueScore >= 20 && data?.meetingFatigueScore <= 40
      ? '#FFA674'
      : '#FF6363';

  return { messageState, fillColor };
};

export const getDeepWorkState = (data) => {
  console.log(data?.deepWorkScore);

  const messageState =
    data?.deepWorkScore < 40
      ? 'negative'
      : data?.deepWorkScore >= 40 && data?.deepWorkScore <= 65
      ? 'warning'
      : 'positive';

  const fillColor =
    data?.deepWorkScore < 40
      ? '#FF6363'
      : data?.deepWorkScore >= 40 && data?.deepWorkScore <= 65
      ? '#FFA674'
      : '#72FEB9';

  return { messageState, fillColor };
};
