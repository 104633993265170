import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowupIcon } from 'assets/icons/arrow-up.svg';
import MeetingHealthImg from 'assets/images/meeting_health.png';
import Counter from 'components/Counter';
import React from 'react';
import { useSelector } from 'react-redux';
import { meetingHealthGrade, number } from 'utils/misc';
import Statistics from '.';

export default function MeetingHealth({ isVisible = true }) {
  const { data } = useSelector((state) => state.misc);
  const healthScore = data?.meetingHealthScore;

  return (
    <Statistics
      title={
        <div className="flex items-center">
          <div className="inline-block text-center" style={{ color: meetingHealthGrade(number(healthScore))?.color }}>
            {meetingHealthGrade(number(healthScore))?.grade}
          </div>
        </div>
      }
      subtitle="Meeting Health Grade ™"
      chart={<img src={MeetingHealthImg} />}>
      <div style={{ marginTop: 24 }}>
        <div className="font-exo2" style={{ fontWeight: 400, fontSize: 18, lineHeight: '24px', color: '#E1E1E1' }}>
          Meeting Health combines Deep Work Score and Meeting Fatigue Score to a single grade.
        </div>
        <div className="font-exo2 mt-4" style={{ fontWeight: 400, fontSize: 18, lineHeight: '24px', color: '#E1E1E1' }}>
          Meeting Health gives a sense of how healthy your meeting habits are. Ideally, you want to do as many
          consecutive meetings (of similar genres) as possible to increase your Deep Work score but not too many that
          could cause fatigue.
        </div>
      </div>
    </Statistics>
  );
}
